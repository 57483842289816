@keyframes upTitleBox{
    from {
      z-index: 3;
    }
  
    to {
      z-index: 1;
    }
}

.popup_cover{width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);opacity: 0;transition: opacity 0.2s}

/*popup*/
.popup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.popup .popupSetion{padding: 0;overflow: hidden;position: fixed;background: #fff;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 1;border-radius: 18px;width: calc(100% - 40px);max-width: 320px;max-height: calc(100% - 40px);opacity: 0;transition: opacity 0.2s}
.popupTextBox{padding: 20px}
.btnPopupSetion .popupSetion{padding-bottom: calc(62px + 1.313em + 20px)}
.popup.active .popup_cover{opacity: 1}
.popup.active .popupSetion{opacity: 1}
.popupTitle{font-size: 16px;font-weight: 700;margin: 0 0 10px}
.popupTitle>p{display: inline-block;margin: 0 0 0 6px;background: #303036;border-radius: 5px;padding: 2px 6px;font-size: 11px;color: rgba(255, 255, 255, 0.6);transform: translateY(-1px)}
.popupTitle>p>span{color: rgba(255, 255, 255, 0.9)}
.popupText{color: #3B3B40;font-size: 14px}
.popupText.rColor{color: #F44D4D}
.popupText .rColor{color: #F44D4D}
.popup_btnBox{padding: 0 20px 20px;display: flex;width: 100%;justify-content: flex-end;gap: 10px}
.btn_popup{background: #000;border:1px solid #000;min-width: 82px;border-radius: 100px;height: 34px;font-size: 14px;font-weight: 500;display: flex;align-items: center;justify-content: center;color: #fff;padding: 0 10px}
.btn_popup.line{background: #fff;border-color: #000;color: #000}
.btn_popup[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup:disabled:not(.btn_popup[disabled=false]){background: #cdcdd0;border-color: #cdcdd0;cursor: auto}
.btn_popup.line[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup.line:disabled:not(.btn_popup[disabled=false]){background: #cdcdd0;border-color: #cdcdd0;cursor: auto}

/*rightPopup*/
.rightPopupClose{position: absolute;top: 50%;right: 20px;transform: translateY(-50%)}
.rightPopup{background: #fff;position: fixed;top: 0;right: 0;width: 100%;height: 100%;transform: translateX(100%);z-index: 9000;transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.rightPopup.active{transform: translateX(0)}
.rightPopup .popup_cover{display: none}
.rightPopup_section{height: 100%;background: #fff}
.rightPopup_header_section{height: 54px;position: relative;padding: 0 20px;display: flex;align-items: center;z-index: 10}
.rightPopup_contents{height: calc(100% - 54px);overflow-y: auto;padding: 20px 20px 50px}
.rightPopup_title{font-size: 14px;font-weight: 500;padding: 0 25px;display: flex;width: 100%;height: 54px;align-items: center;text-align: center;justify-content: center;letter-spacing: -1px;line-height: 1.2}
.termsText{padding: 20px;font-size: 15px;line-height: 25px;white-space: pre-wrap}
.rightPopup{background: rgba(53, 53, 53, 0.9);backdrop-filter: blur(5.5px);-webkit-backdrop-filter: blur(5.5px)}

.rightPopup.bottomBtnText .rightPopup_contents{padding-bottom: 200px}
.rightPopup.bottomBtn .rightPopup_contents{padding-bottom: 100px}
.rightPopup.sidePadding .rightPopup_contents{padding-left: 20px;padding-right: 20px}

/*selPopup*/
.selPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.selPopupSetion{border-top-left-radius: 20px;border-top-right-radius: 20px;padding: 20px 0 0;background: #303036;position: absolute;bottom: 0;left: 0;z-index: 1;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.selPopupTextBox{padding: 0 20px 10px}
.selPopup_selBox{padding: 0 0 30px;max-height: 300px;overflow-y: auto}
.selPopup_sel{color: rgba(255, 255, 255, 0.9);padding: 10px 20px;text-align: left;border-radius: 0;border: 0;display: block;width: 100%;font-size: 14px;font-weight: 400}
.selPopup.active .popup_cover{opacity: 1}
.selPopup.active .selPopupSetion{transform: translateY(0)}
.selPopup_sel.active{font-weight: 600;background: url(/assets/images/basic/selPopup_active.svg) no-repeat center right 20px}
.selPopup .popupHaeder{position: relative;padding: 0 0 14px}
.selPopup .popupHaederTitle{font-size: 16px;font-weight: 700;color: #fff;padding: 0 20px}
.popupClose{position: absolute;right: 20px;top: -2px}
.selPopup.selBgType .selPopup_sel.active{background-color:rgba(34, 34, 37, 1)}

/*toastPopup*/
.toastPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.toastPopup .popupSetion{border-top-left-radius: 15px;border-top-right-radius: 15px;display: flex;flex-direction: column;max-height: calc(100% - 40px);background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;padding: 20px 20px 110px;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.toastPopup.active .popup_cover{opacity: 1}
.toastPopup.active .popupSetion{transform: translateY(0)}
.toastPopup.active.notBtn .popupSetion{padding-bottom: 30px}
.toastPopup .popupHaeder{min-height: 26px;position: relative;display: flex;align-items: center;justify-content: space-between;gap: 15px;margin: 0 0 15px}
.toastPopup .popupHaederTitle{text-align: left;font-size: 14px;font-weight: 700;color: #000;display: flex;align-items: center;gap: 6px}
.toastPopup .popupContents{overflow-y: auto}
.toastPopup .popupClose{position: absolute;right: 0;top: -2px}

.toastPopup_btnBox{position: absolute;left: 0;bottom: 0;width: 100%;min-height: 55px;border: 0;justify-content: space-between}
.toastPopup_btnBox .btn_popup{width: 100%;height: 55px;background: var(--mainColor);border: 1px solid var(--mainColor);color: #0B0B0C;font-weight: 700;font-size: 15px;border-radius: 0;padding: 5px 10px;display: flex;align-items: center;justify-content: center}
.popup_btnChkBox{min-height: 20px;margin: 20px 0 0}
.popup_btnChkBox + .toastPopup_btnBox{margin-top: 20px}

.toastBasicContents{padding: 0 0 20px}
.toastBasicContents_title{font-size: 16px;font-weight: 700}
.toastBasicContents_text{font-size: 13px;color: rgba(255, 255, 255, 0.9);margin: 2px 0 0}

/*slidePopup*/
.slideUpPopup{position: fixed;top: 70px;left: 0;width: 100%;height: calc(100% - 130px);background: var(--mainColor);box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);border-radius: 20px 20px 0px 0px;z-index: 1002;transform: translateY(calc(100% - 82px));transition: background 0.2s}
.slideUpPopup.active{transition: background 0.2s, transform 0.395s}
.slideUpPopup.open{background: #303036}
.scroll_sel_icon{width: 100%;height: 30px;display: flex;align-items: center;justify-content: center}
.slideUpPopup.open .scroll_sel_icon img{transition: transform 0.2s;transform: rotate(180deg)}
.slideUpPopup.open .slideUpPopup_titleBox{opacity: 0;z-index: 1;animation: upTitleBox 0.2s 0s forwards}
.slideUpPopup_titleBox{display: flex;align-items: center;transition: opacity 0.2s;opacity: 1;background: var(--mainColor);position: relative;width: 100%;z-index: 3;justify-content: space-between;gap: 10px;min-height: 50px}
.slideUpPopup_contents{position: absolute;left: 0;width: 100%;height: calc(100% - 30px);top: 30px;overflow-y: auto;z-index: 2;background: transparent;opacity: 0;transition: opacity 0.2s}
.slideUpPopup.open .slideUpPopup_contents{opacity: 1}
.slideUpPopup_cover{background: rgba(0, 0, 0, 0.6);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1001;display: none;opacity: 0;transition: opacity 0.2s}
.slideUpPopup_cover.active{display: block;opacity: 1}

/*customPopup*/
.customPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.customPopup .popupSetion{padding: 0;overflow: hidden;position: fixed;background: #fff;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 1;border-radius: 18px;width: calc(100% - 40px);max-width: 380px;max-height: calc(100% - 40px);opacity: 0;transition: opacity 0.2s}
.customPopup.active .popup_cover{opacity: 1}
.customPopup.active .popupSetion{opacity: 1}

.customPopup .popupTextBox{padding: 0 20px;height: 5.63em;display: flex;align-items: center;justify-content: center;position: fixed;top: 0;left: 0;width: 100%;background: #fff;z-index: 10}
.customPopup .popupTitle{text-align: center;font-size: 1.125em;margin: 0}
.customPopup .popupSubTitle{text-align: center;font-size: 0.875em;margin: 10px 0 0}
.customPopup .popupTextBox.popupTextBox_subTitle{flex-direction: column;height: 8.13em}
.customPopup .popupTextBox.popupTextBox_subTitle + .customPopup_conternts{padding: calc(8.13em + 20px) 20px 160px}
.customPopup_conternts{max-height: calc(100vh - 40px);overflow-x: auto;padding: calc(5.63em + 20px) 20px 160px}
.customPopupClose{position: absolute;top: 20px;right: 20px;display: none}

@media (max-width:1024px){
    .customPopup .popupSetion{border-radius: 0;width: 100%;height: 100%;max-width: 100%;max-height: 100%}
    .customPopupClose{display: block}
    .customPopup .popupTextBox{height: 54px}
    .customPopup_conternts{padding: calc(54px + 20px) 20px 160px}
}