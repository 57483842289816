@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

.gTextColor{color:var(--mainColor) !important}
.oTextColor{color:#FF5100 !important}
.boldText{font-weight: 700 !important}
.underLineText{text-decoration: underline}
.backgroundImg{background-repeat: no-repeat;background-position: center;background-size: cover}
.mContents{display: none !important}

.rTextColor{color:var(--rTextColor) !important}
.rBg{background-color:var(--rBgColor) !important}

.copyInput{position: fixed;top: 0;left: 0;transform: translate(-101%,-101%)}
.myOrderDeliveryArea{position: fixed;top: 0;left: 0;transform: translate(-101%,-101%)}

.dotTextBox{display: flex;align-items: flex-start;text-align: left}
.dotTextBox>span{background: #000;width: 3px;height: 3px;border-radius: 100%;margin: 8px 7px 0}
.dotText{font-size: 0.875em}

.vreview-collection-widget-iframe{width:100% !important;max-width: 100% !important}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;z-index: 1000;background: #fff}
.headerSection{padding: 0 20px;position: relative;height: 6.25em;max-width: 1640px;margin: 0 auto;display: flex;align-items: center;justify-content: space-between;gap: 40px}
.pcheader{border-bottom: 1px solid #E3E3E3}
.mheader{display: none;height: 54px}

.header_subBanner{display: none;position: relative;background: #FF5100;height: 3.75em}
.header_subBanner_item{width: auto;display: flex;align-items: center;justify-content: center;height: 100%;gap: 14px;font-weight: 700;color: #fff;padding: 0 64px}
.header_subBanner_item>span{display: block;border-radius: 23px;padding: 2px 11px;background: #fff;color: #FF5100;font-size: 13px}
.header_subBanner_close{position: absolute;right: 0;top: 0;height: 100%;width: 64px;display: flex;align-items: center;justify-content: center}

.header_loginLinkArea{border-bottom: 1px solid #E3E3E3;height: 2.5em;padding: 0 20px}
.header_loginLinkBox{height: 100%;display: flex;max-width: 1280px;margin: 0 auto;align-items: center;justify-content: flex-end;gap: 20px}
.header_loginLink{position: relative;font-size: 12px}
.header_loginLink + .header_loginLink::before{content: "";position: absolute;width: 1px;height: 10px;background: #333;top: 50%;transform: translateY(-50%);left: -9px}

.headerArea{display: flex;align-items: center;gap: 2.5em}
.headerLogo{width: 10.25em}
.headerLogo>img{width: 100%}
.header_subLinkBox{display: flex;align-items: center;gap: 27px}
.header_subLink{display: flex;align-items: center;gap: 6px;font-weight: 700;position: relative}
.header_subLink + .header_subLink::before{content: "";width: 1px;height: 10px;background: rgba(0, 0, 0, 0.1);position: absolute;top: 50%;transform: translateY(-50%);left: -13.5px}
.header_subLink.active{color: #FF5100}
.header_subLink:nth-child(2).active{color: #4FB1FF}

.pcheader_subMenu{width: 18.75em;position: absolute;background: #fff;border-radius: 8px;padding: 20px;right: 0;top: calc(6.25em + 20px);display: none;border: 1px solid #E3E3E3}
.pcheader_subMenu.active{display: block}
.pcheader_subMenu_area + .pcheader_subMenu_area{padding: 20px 0 0;margin: 20px 0 0;border-top: 1px solid rgba(0, 0, 0, 0.1)}
.pcheader_subMenu_item{display: block;opacity: 0.5;font-weight: 500}
.pcheader_subMenu_item.active{opacity: 1}
.pcheader_subMenu_item + .pcheader_subMenu_item{margin: 1.25em 0 0}

.header_link{font-weight: 700}

.header_subMenu_openBtn{border: 1px solid #000;border-radius: 24px;display: flex;gap: 7px;padding: 0.75em 1.13em;align-items: center}
.header_userName{font-weight: 700}
.header_cartCount{font-size: 12px;font-weight: 700;color: #fff;background: #FF5100;border-radius: 100%;width: 20px;height: 20px;line-height: 1;display: flex;align-items: center;justify-content: center}
.header_subMenu_openBtn.active .header_subMenu_openBtn_icon{transform: rotate(180deg)}

.btnLogo{width: 98px}
.btnLogo>img{width: 100%}

.btnMenuOpen{width: 20px;height: 20px;position: relative}
.btnMenuOpen span{display: block;width: 100%;height: 2px;background: #1e1e1e;border-radius: 10px;position: absolute;transition: transform 0.2s, opacity 0.2s}
.btnMenuOpen span:nth-child(1){top: 0;left: 0}
.btnMenuOpen span:nth-child(2){top: calc(50% - 1px);left: 0}
.btnMenuOpen span:nth-child(3){bottom: 1px;left: 0}

.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRightItem{display: block;color: var(--mainTextColor);text-decoration: underline;font-size: 13px;font-weight: 600}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.headTitle{font-size: 14px;font-weight: 600;display: flex;width: 100%;height: 54px;align-items: center;gap: 4px;justify-content: center;text-align: center}
.btnHeaderRightLink{font-size: 13px;font-weight: 300;color: #ACACAC;text-decoration: underline;text-align: right}

.headerInfoBox.btnHeaderRight{display: flex;align-items: center;justify-content: flex-end;gap: 14px}

.sideMenu{position: fixed;top: 0;height: 100%;width: calc(100% - 70px);overflow-y: auto;left: -101%;transition: left 0.2s;background: #fff;z-index: 1;display: flex;flex-direction: column;justify-content: space-between}
.sideMenu.active{left: 0}
.sideCover{background: rgba(0,0,0,0.6);position: fixed;top: 0;left: 0;width:100%;height: 100%;opacity: 0;transition: opacity 0.2s;display: none}
.sideCover.active{opacity: 1;display: block}
.sideMenu_section{height: calc(100% - 70px);display: flex;flex-direction: column;justify-content: flex-start}
.sideMenu_subBanner{display: flex;align-items: center;justify-content: space-between;gap: 15px;background: #FF5100;padding: 10px 20px;font-size: 13px;color: #fff;font-weight: 700;min-height: 48px}
.sideMenu_userInfoBox{display: flex;align-items: center;justify-content: space-between;gap: 20px;padding: 10px 20px;width: 100%;min-height: 78px}
.sideMenu_userName{color: rgba(0, 0, 0, 0.8);font-size: 24px;font-weight: 700}
.sideMenu_area{height: calc(100% - 78px);overflow-y: auto;padding: 20px 0 0}
.sideMenu_link{color: rgba(0, 0, 0, 0.8);font-size: 16px;font-weight: 500;padding: 13px 20px;display: block;width: 100%;text-align: left}
.sideMenu_subSection{min-height: 70px;padding: 10px 20px;display: flex;align-items: center;justify-content: center;gap: 40px}
.sideMenu_subLink{display: flex;align-items: center;gap: 6px;font-size: 16px;font-weight: 700;position: relative}
.sideMenu_subLink.active{color: #FF5100}
.sideMenu_subLink:nth-child(2).active{color: #4FB1FF}
.sideMenu_subLink + .sideMenu_subLink::before{content: "";width: 1px;height: 10px;background: rgba(0, 0, 0, 0.1);position: absolute;top: 50%;transform: translateY(-50%);left: -20px}


/*footer*/
.footer{border-top: 1px solid #E3E3E3;height: 19.38em;display: flex;align-items: center;justify-content: center}
.footerSection{max-width: 1640px;margin: 0 auto;padding: 20px;width: 100%}
.footer_menuBox{display: flex;align-items: center;opacity: 0.5;gap: 1.25em}
.footer_menuBox>span{display: block;width: 2px;height: 13px;background: #333;margin: -2px 0 0}
.footer_menu{color: #333;font-size: 1em;font-weight: 700}
.footer_infoArea{display: flex;align-items: center;justify-content: space-between;gap: 2.5em;padding: 10px 0}
.footer_infoBox{width: calc(100% - 7.5em - 7.5em - 1.88em);display: flex;flex-wrap: wrap;gap: 1.25em;opacity: 0.5}
.footer_iconBox{display: flex;align-items: center;justify-content: flex-end;gap: 1.88em}
.footer_iconBox>img{width: 7.5em}
.footer_caption{font-weight: 500;opacity: 0.5}

/*editerBox*/
.editerBox *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: revert;white-space: pre-wrap}
.editerBox img{max-width: 100%}

/*terms*/
.terms_contentsBox{max-width: 1220px;margin: 0 auto}
.terms_contentsBox.pageType{padding: 60px 20px 100px}
.terms_title{font-size: 1.38em;font-weight: 700}
.terms_contetns{margin: 1.88em auto 0}
.termsCustomPopup .popupSetion{max-width: 600px;min-height: 70%}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 100px;transform: translateX(-50%);max-width: 400px;text-align: center;padding: 9px 18px;color: #fff;font-size: 13px;font-weight: 500;opacity: 0;background: rgba(58, 58, 58, 0.8);border-radius: 8px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)}
.loading>img{width: 3.13em;animation: spin 2s infinite}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 22px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

/*page*/
.pageTitleBox{width: 100%;padding: 20px 0 30px}
.pageTitle{color: var(--mainTextColor);font-size: 1.38em;font-weight: 700}
.pageSubTitle{font-weight: 500;margin: 4px 0 0;color: var(--mainTextColor);font-size: 13px}
.pageSubTitleBtn{color: var(--mainTextColor);font-size: 13px;font-weight: 500;margin: 14px 0 0;text-decoration: underline}
.pageTitleItem{display: flex;align-items: center;gap: 6px}
.btnPageTitleBack{min-width: 2.13em;width:2.13em}
.btnPageTitleBack img{width:100%}

/*contentsSection*/
.contentsSection{min-height: calc(var(--vh, 1vh) * 100)}
.contentsSection.gBg{background-color: #F9F9F9}
.contentsSection.bottomContents{display: flex;align-items: center;flex-direction: column;justify-content: space-between}
.contentsSection.headerContents{padding-top: calc(2.5em + 6.25em)}
.contentsSection.headerBannerContents{padding-top: calc(3.75em + 2.5em + 6.25em)}
.contentsSection.footerContents,.contentsSection.pcFooterContents{min-height: calc(100vh - 19.38em)}

.itemSection{padding: 20px}
.itemSection + .itemSection{border-top: 6px solid #303036}
.itemSection.notPaddingTop{padding-top: 0}
.itemSection.notPaddingSide{padding-left: 0;padding-right: 0}
.itemSection + .itemSection.minLine{border-width: 1px}

/*tap*/
.tapBox{height: 100%;display: flex;align-items: center;gap: 14px;overflow-x: auto;border-bottom: 1px solid #E3E3E3}
.tapItem{height: 100%;white-space: nowrap;font-size: 13px;padding: 7px 0;color: #6E6E77;font-weight: 500;border-bottom: 2px solid transparent}
.tapItem.active{font-weight: 700;border-color: var(--mainTextColor);color: var(--mainTextColor)}
.tapBox.heightSet{height: 44px}

.tapBox.headerComTap{width: 100%;position: absolute;top: 54px;left: 0;background: #19191B;height: 44px;gap: 0;overflow-x: visible;border-bottom: 1px solid #303036}
.tapBox.headerComTap .tapItem{padding: 0 10px;width:100%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(2),.tapBox.headerComTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(3),.tapBox.headerComTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(4),.tapBox.headerComTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}

.subTapBox{display: flex;align-items: center;gap: 4px;overflow-x: auto;padding: 15px 20px}
.subTapItem{border-radius: 100px;color: #6E6E77;font-size: 13px;padding: 7px 16px;white-space: nowrap}
.subTapItem.active{background: #303036;font-weight: 600;color: var(--mainTextColor)}

.popupTapBox{width: calc(100% - 30px);display: flex;align-items: center;justify-content: flex-start;gap: 14px}
.popupTapItem{font-size: 16px;font-weight: 700;color: #6E6E77}
.popupTapItem.active{color: var(--mainTextColor)}

/*pageNation*/
.pageNation{display: flex;align-items: center;justify-content: center;gap: 0;margin-top: 3.13em}
.btn_pageNation_prev{margin: 0 10px 0 0;min-width: 28px}
.btn_pageNation_next{margin: 0 0 0 10px;min-width: 28px}
.btn_pageNation img{width:100%}
.btn_pageNation.offPn{opacity: 0.3}
.btn_pageNationNumber{width: 28px;height: 28px;min-width: 28px;font-weight: 500;opacity: 0.3}
.btn_pageNationNumber.active{opacity: 1}

/*notItem*/
.notItemArea{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;text-align: center;height: 100%;width: 100%}
.notItemArea.min{height:auto;padding: 30px 0}
.notItemArea.fullPage{min-height: 60vh}
.notItemText{font-size: 13px;font-weight: 700;color: rgba(0,0,0,0.3)}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 13px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: var(--mainTextColor);font-weight: 800}

/*accordion*/
.accordionTitleBox{width: calc(100% - 40px);margin: 0 auto;border-bottom: 1px solid #303036;align-items: center;padding: 20px 0;display: flex}
.accordionBox.active .accordionTitleBox{border: 0}
.accordionIcon{color: #6E6E77;font-size: 14px;font-weight: 700;min-width: 14px;text-align: left;line-height: 1.8}
.accordionBox.active .questionIcon{color: var(--mainTextColor)}
.accordionTextBox{display: flex;align-items: flex-start;width: calc(100% - 5px - 11px);padding-right: 16px}
.accordionTitle{text-align: left;width: 100%;font-size: 14px;font-weight: 600;line-height: 1.8}
.accordionTextBox + img{min-width: 11px;transition: transform 0.2s,opacity 0.2s;opacity: 0.5}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg);opacity: 1}
.answerText{background: #222225;padding: 20px 20px 30px}
.accordionText{font-size: 13px;line-height: 20px;color: rgba(255, 255, 255, 0.9)}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.input_section{padding: 0 20px 50px;width: 100%}

.inputItemBox{margin: 20px 0 0;position: relative}
.inputItemBox:first-child{margin-top: 0}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{font-size: 13px;color: #0D0D0E;margin: 0 0 4px;font-weight: 700}
.inputName>span{font-weight: 400}
.inputItem{border-bottom: 1px solid #E1E1E2;width: 100%;padding: 9px 0;font-size: 14px;color: #000}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #1E1E1E;background: url(/assets/images/basic/sel_icon.svg) no-repeat center right 0;background-color: var(--mainTextColor);padding-right: 40px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputSet.pwBox .inputItem{padding-right: 50px}
.btn_pwCh{position: absolute;right: 8px;top: 50%;transform: translateY(-50%)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 90px}
.btn_inputSubBtn{min-width: 70px;min-height: 30px;border: 1px solid #000;background: #fff;border-radius: 100px;color: var(--mainTextColor);font-size: 13px;position: absolute;right: 0;top: 50%;transform: translateY(-50%);padding: 2px 3px}
.btn_inputSubBtn:disabled{color: #B5B5B9;border-color: #B5B5B9}
.cetifiedTimer{font-size: 13px;font-weight: 500;color: #FF5100;position: absolute;right: 80px;top: 50%;transform: translateY(-50%)}
.cetifiedInput .inputItem{padding-right: 122px}
.errMsg{color: #F04C41;margin: 4px 0 0;font-size: 12px;font-weight: 500}
.inputMsgBox .errMsg{margin: 0}
.inputMsgBox{margin: 4px 0 0;display: flex;gap: 10px;justify-content: space-between}
.inputMsgBox.contentEnd{justify-content: flex-end}
.btn_inputSubItem{font-size: 12px;font-weight: 500;color: var(--mainTextColor);text-decoration: underline;text-align: right}
.inputCaption{margin: 4px 0 0;font-size: 12px;color: rgba(255, 255, 255, 0.6);font-weight: 500}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 85px}
.inputUnit{font-size: 15px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.inputBox.selectIn .inputItem{padding-left: 115px}
.inputBox.selectIn .inputItem + .inputItemBox{width: 90px;margin: 0;position: absolute;top: 50%;left: 15px;transform: translateY(-50%)}
.inputItemBox.active .inputBox.selectIn .inputItem + .inputItemBox{transform: translateY(calc(-50% + 7px))}
.inputBox.selectInItem .inputItem{font-size: 11px;padding: 2px 6px !important;border-radius: 5px;background-color: #6E6E77;border-color: #6E6E77;color: var(--mainTextColor);display: block}
.inputBox.selectInItem .selItem .inputItem{background-size: 20px;background-position: center right 5px}
.inputBox.selectInItem .selItem .inputItem::placeholder{color:#fff}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 16px 0 0}
.chk_item.allChkItem{margin: 0 0 20px}
.chk_item.allChkItem .chk_item_text{font-weight: 600;font-size: 16px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item.notText label{padding: 10px}
.chk_item.notText{padding: 0}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item.minChk label::before{background-image: url(/assets/images/basic/chk_min_off.svg)}
.chk_item.minChk input:checked + label::before{background-image: url(/assets/images/basic/chk_min_on.svg)}
.chk_item_text{color: var(--mainTextColor);font-size: 14px;font-weight: 500}
.chk_item_text>span{color:#B5B5B9}
.chkBoxBtn{color:#FF5100;text-decoration: underline;font-size: 12px;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}

.textareaItem{display: block;resize: none;min-height: 180px;border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 12px 15px;font-size: 14px;color: var(--mainTextColor)}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtn{top: 14px;transform: translateY(0)}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border-radius: 14px;display: flex;align-items: center;justify-content: center;background: #303036}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 10px center;background-size: 15px;background-color: #fff;color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.customSelectInputBox{position: relative}
.customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.customSelectInputBox::after{content: "";width:11px;height:6px;position: absolute;background: url(/assets/images/basic/sel.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customSelectInputBox .inputItem{background: #fff;color: var(--mainTextColor);padding-right: 34px;border-bottom: 1px solid #E1E1E2}
.customSelectInputBox .inputItem:disabled{color: var(--disabledColor);background: var(--disabledBg)}
.customSelect_optionBox{width: calc(100% - 2px);border: 1px solid #E1E1E2;border-radius: 5px;margin: 5px 0 0;background: #fff;overflow: hidden;display: none;max-height: 234px;overflow-y: auto}
.customSelect_optionBox.active{display: block}
.customSelect_option{font-size:13px;display: block;color: #0D0D0E;width: 100%;text-align: left;padding: 10px}
.customSelect_option.active{font-weight: 600}

.customSelect_optionBox::-webkit-scrollbar,
.customSelect_optionBox::-webkit-scrollbar-thumb {height: 10px;max-width:10px;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent}
.customSelect_optionBox::-webkit-scrollbar-thumb {box-shadow: inset 0 0 0 4px #939393}
.customSelect_optionBox::-webkit-scrollbar-track {background-color: #F9F8FA}

.inputSubBox{display: flex;gap: 15px;align-items: flex-start;justify-content: space-between;margin: 20px 0 0}
.inputSubBox .inputItemBox{width: calc(50% - 7.5px);margin: 0}
.inputSubBox .inputSubBox{width: calc(50% - 7.5px);margin: 0}

.inputSubBoxOnly{margin: 20px 0 0}
.inputName.pageName{animation: none;transform: translate(0,0);position: static;color: var(--mainTextColor);font-size: 14px;font-weight: 700;opacity: 1}
.inputName.pageName + .inputItemBox{margin-top: 8px}

.inputViewBox{margin: 8px 0 0}
.inputViewText{border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 14px;color: var(--mainTextColor);background: #303036}
.inputViewBox.textAreaViewBox .inputViewText{display: block;resize: none;min-height: 180px;border-radius: 10px;padding: 12px 15px}
.fileViewBox{margin: 8px 0 0;width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.fileViewBox>div{width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}

.inputTapItemBox{display: flex;justify-content: space-between;gap: 10px;flex-wrap: wrap}
.inputTapItem{width: calc(50% - 5px);border-radius: 8px;padding: 15px;font-size: 14px;font-weight: 500;border: 1px solid #E1E1E2;color:#cdcdd0}
.inputTapItem:disabled{cursor: auto}
.inputTapItem.active{color: #0D0D0E;font-weight: 700;border-color: #000}

.inputTapItem.oColor{border-color: #FF9E18;color: #FF9E18}
.inputTapItem.bColor{border-color: #00ABE4;color: #00ABE4}
.inputTapItem.gColor{border-color: #44AC34;color: #44AC34}
.inputTapItem.pColor{border-color: #D83BB3;color: #D83BB3}

.inputTapItem.opacityActive{opacity: 0.3}
.inputTapItem.opacityActive.active{opacity: 1}

.inputTapItem.alignLeft{text-align: left}

.inputSet.registrationNumberBox{display: flex;justify-content: space-between;gap: 10px}
.inputSet.registrationNumberBox > .inputItem{width: calc(50% - 14px)}
.inputSet.registrationNumberBox > span{text-align: center;width: 8px;display: flex;align-items: center;justify-content: center}
.registrationNumberBackBox{width: calc(50% - 14px);display: flex;align-items: center;gap: 10px}
.registrationNumberBackBox .inputItem{width: 40px;min-width: 40px}
.registrationNumberBack_text{width: calc(100% - 50px);font-size: 14px;letter-spacing: 4px}

.inputBox.shIconInput{background: url(/assets/images/basic/sh_icon.svg) no-repeat center right}
.inputBox.shIconInput .inputItem{background: transparent;padding-right: 35px}

/*datepicker*/
.react-datepicker__tab-loop{width: 100%}
.react-datepicker__tab-loop__start{display: none}
.react-datepicker-popper{padding: 0;width: 100%;z-index: 10;max-width: 380px;z-index: 25}
.react-datepicker{background: #fff;border-radius: 12px;border: 1px solid #000;margin: 0 0 20px;padding: 0;font-size: 14px;width: 100%;display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.react-datepicker__day-names{margin: 0}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{display: none}
.react-datepicker-popper[data-placement^=top]{padding-bottom: 6px}
.react-datepicker-popper[data-placement^=bottom]{padding-top: 6px}
.react-datepicker__month-container{width: 100%}
.react-datepicker__header{background: transparent;border: 0;padding: 0 0 0}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{padding: 20px;color: #000;font-size: 14px;font-weight: 700;margin: 0}
.react-datepicker__day-names{font-size: 11px;display: flex;align-items: center;justify-content: space-between}
.react-datepicker__month{margin: 0}
.react-datepicker__day-names, .react-datepicker__week{display: flex;align-items: center;justify-content: space-between;padding: 0 5px}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{min-width: 14.28%;margin: 0;height: 42px;display: flex;align-items: center;justify-content: center;font-weight: 500;color: #fff;line-height: 1}
.react-datepicker__day-name{height: 36px;color: #888892}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{color: #464650}
.react-datepicker__day--disabled::before, .react-datepicker__month-text--disabled::before, .react-datepicker__quarter-text--disabled::before, .react-datepicker__year-text--disabled::before{display: none}
.react-datepicker__navigation{width: 18px;height: 18px;background-size: 100%;top: 20px}
.react-datepicker__navigation:disabled{opacity: 0.4}
.react-datepicker__navigation--previous{left: 14px;background: url(/assets/images/basic/cal_left.svg) no-repeat center}
.react-datepicker__navigation--next{right: 14px;background: url(/assets/images/basic/cal_right.svg) no-repeat center}
.react-datepicker__navigation-icon{display: none}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{background: transparent;position: relative}
.react-datepicker__day.react-datepicker__day--in-range,.react-datepicker__day.react-datepicker__day--in-selecting-range:hover{background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--disabled,.react-datepicker__day--outside-month{background: transparent !important;opacity: 0.3}
.react-datepicker__day span{position:relative;z-index: 1}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){background: transparent}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range):not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--in-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){position: relative;background: transparent !important}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 30px;width: 30px;border: 1px solid #FF5100;border-radius: 100% !important;background: #FF5100 !important;color: #fff}
.react-datepicker__day--selected span,.react-datepicker__day--range-end span,.react-datepicker__day--selecting-range-start span,.react-datepicker__day--selecting-range-end span{color:#0B0B0C;font-weight: 700}
.react-datepicker__day--keyboard-selected{background: transparent !important}
.react-datepicker__day--selected span, .react-datepicker__day--range-end span, .react-datepicker__day--selecting-range-start span, .react-datepicker__day--selecting-range-end span{color:#fff}
.react-datepicker__triangle{display: none}
/* .react-datepicker__day::before, .react-datepicker__time-name::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 36px;width: 36px;border: 1px solid #303036;border-radius: 100% !important;background: #303036 !important;color: #0B0B0C} */
.react-datepicker__day, .react-datepicker__time-name{color: #333;position:relative}
.itemSectionTitleBox + .inputItemBox .react-datepicker{margin-top: 20px}

/*fixedBox*/
.fixedPoint{position: relative}
.hdFtFixedItem{position: absolute;bottom: 0}
.headerFixedItem{position: fixed;z-index: 1000}
.fixedItem{position: fixed;z-index: 1000}

/*member*/
.welcome_sns{margin: 5em auto 1.38em;text-align: center;position: relative}
.welcome_sns::before{content: "";position: absolute;top: 50%;left: 0;width: 100%;height: 1px;background: #EEEFF1}
.welcome_sns_text{color: #7C7C80;text-align: center;font-size: 13px;padding: 0 20px;display: inline-block;position: relative;z-index: 1;background: #fff}
.input_contents{padding: 4.38em 20px 6.88em;margin: 0 auto;max-width: 420px}
.input_contents_titleBox{margin: 0 0 4em}
.input_contents_titleBox>img{margin: 0 auto 10px;width: 60px}
.input_contents_title{font-size: 1.5em;font-weight: 700;color: #333;text-align: center}
.input_contents_subTitle{font-size: 0.875em;color: #3b3b40;text-align: center;margin: 3px 0 0}
.input_contents_subTitle .input_contents_subTitleCaption{color: rgba(59, 59, 64, 0.5);font-size: 11px;margin: 6px 0 0;display: block}
.welComeBtn{font-size: 0.875em;width: 100%;display: block;color: #fff;padding: 15px 20px;background: #FF5100;border:1px solid #FF5100;border-radius: 4px;font-weight: 700;position: relative;text-align: center}
.welComeBtn + .welComeBtn{margin: 12px 0 0}
.welComeBtn.bColor{background: #000;border-color: #000}
.welComeBtn.kakao{background: #FFE812;border-color: #FFE812}
.welComeBtn.naver{background: #00D070;border-color: #00D070}
.welComeBtn.google{background: #fff;border-color: #E3E3E3;color:#333}
.welComeBtn.kakao,.welComeBtn.naver,.welComeBtn.google{padding-left: 52px;padding-right: 52px}
.welComeBtn.kakao img,.welComeBtn.naver img,.welComeBtn.google img{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.welComeBtn.textBtn{background: #fff;width:auto;text-decoration: underline;font-weight: 500;padding: 0;border:0;color:#3B3B40;margin: 2.86em auto 0}

.loginSubLinkBox{display: flex;align-items: center;justify-content: space-between;margin: 40px 0 0}
.loginSubLink{font-size: 0.875em;text-align: center;width: calc(50% - 0.5px);padding: 0 10px;font-weight: 500;color: #3B3B40}
.loginSubLinkBox span{background: #E1E1E2;height: 12px;width: 1px;display: block}

.Input_chk_section{background: #F9F9FB;border-radius: 8px;padding: 15px}

.input_section_subTitleBox{margin: 40px auto}
.input_section_subTitle{color: #3B3B40;font-size: 14px;font-weight: 700}

.find_id_text{font-size: 1.38em;font-weight: 700;text-align: center;margin: 0 0 5em}

/*main*/
.mainPage{padding-bottom: 7.5em}
.mainBannerImg{height: 26.88em;width: 100%;display: block}
.mainBannerControll .swiper-button-prev{position: absolute;width: 3.75em;height: 3.75em;background: url(/assets/images/icon/main_banner_prev.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;left: 20px}
.mainBannerControll .swiper-button-next{position: absolute;width: 3.75em;height: 3.75em;background: url(/assets/images/icon/main_banner_next.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;right: 20px}
.mainBannerControll{position: absolute;width: 100%;max-width: 1640px;top: 50%;left: 50%;transform: translateX(-50%);z-index: 10}
.mainBannerControllBox{position: relative;transform: translateY(-1.88em)}

.mainDataSection{max-width: 1640px;margin: -20px auto 0;padding: 0 20px;position: relative;z-index: 10;display: flex;justify-content: space-between;gap: 20px}
.mainDataArea{border-radius: 24px;padding: 2.5em;position: relative;overflow: hidden;width: 100%}
.mainDataArea.row1{width:calc(25% - 10px)}
.mainDataArea.row3{width:calc(75% - 10px)}
.mainDataArea.textCenter{text-align: center}
.mainDataArea::before{content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: rgba(255, 255, 255, 0.7);backdrop-filter: blur(7px)}
.mainDataBox{position: relative;z-index: 1}
.mainDataTitle{font-size: 1.5em;font-weight: 700}
.mainDataSubText{font-weight: 500;margin: 1.25em 0 1.88em}
.mainDataTitleBox{display: flex;align-items: center;justify-content: space-between;gap: 20px}
.mainDataInfo{display: flex;gap: 14px;margin: 2em 0 0}
.mainDataInfoItem{width: calc(50% - 7px);border: 1px solid #E2E2E2;border-radius: 15px;text-align: center;padding: 2em 5px}
.mainDataInfoItem_name{font-weight: 700;font-size: 1.06em}
.mainDataInfoItem_val{margin: 2px 0 0;font-size: 1.5em;color: #1C9BFF;font-weight: 700}
.mainDataInfoItem_val span{color:#000;font-weight: 400;opacity: 0.5;font-size: 0.65em}
.mainDataHealthInfo{display: flex;gap: 10px;margin: 2em 0 0}
.mainDataHealthInfoItem{width: calc(25% - 7.5px);border-radius: 15px;background: #F4F4F4;padding: 2em 20px;display: flex;align-items: center;gap: 10px}
.mainDataHealthInfoItem>img{width: 3.13em}
.mainDataHealthInfoItem_info{width: calc(100% - 3.13em - 10px)}
.mainDataHealthInfoItem_infoNameBox{display: flex;gap: 6px;align-items: center;width: 100%;flex-wrap: wrap}
.mainDataHealthInfoItem_name{font-weight: 700}
.mainDataHealthInfoItem_val{margin: 2px 0 0;font-size: 1.5em;font-weight: 700}
.mainDataHealthInfoItem_val.rColor{color: #EC5049}
.mainDataHealthInfoItem_val.gColor{color: #24B300}
.mainDataHealthInfoItem_val.oColor{color: #FF9E18}
.mainDataHealthInfoItem_val span{color:#000;font-weight: 400;opacity: 0.5;font-size: 0.65em}
.mainDataHealthInfo_notDataBtnBox{display: flex;align-items: center;justify-content: center;gap: 30px}

.mainSubBannerImg{width: 100%;height: 20.63em;display: block}
.mainSubBanner{margin: 5em 0 0}

.mainNotMember{position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, 0.7);width: 100%;height: 100%;z-index: 11;display: flex;align-items: center;justify-content: center;padding: 20px}
.mainNotMemberBox{display: flex;flex-direction: column;gap: 16px;justify-content: center;align-items: center;text-align: center;max-width: 333px}
.mainNotMember_title{font-weight: 700;color: #fff;font-size: 1.25em}
.mainNotMember_text{color: #fff}
.mainNotMember_btn{color: #fff;padding: 0.75em 12px;border: 1px solid #fff;border-radius: 100px;display: flex;align-items: center;justify-content: space-between;gap: 7px;font-weight: 700;line-height: 1}
.mainNotMember_btn>img{margin: -1px 0 0}

.dataCaption{font-size: 11px;padding: 3px 7px;border-radius: 4px;background: rgba(0,0,0,0.1)}
.dataCaption.rColor{background: rgba(236, 80, 73, 0.1);color: #EC5049}
.dataCaption.oColor{background: rgba(255, 158, 24, 0.1);color: #FF9E18}
.dataCaption.gColor{background: rgba(36, 179, 0, 0.1);color: #24B300}
.dataCaption.upSize{font-size: 13px;padding: 3px 10px;display: inline-block}
.dataBtn{border: 1px solid #000;border-radius: 100px;display: inline-flex;align-items: center;justify-content: center;padding: 10px 14px;font-weight: 700;gap: 5px}

/*product*/
.subProductList_notItem .subProductList_itemBox{min-width: calc(100% - 19.06em)}
.subProductListSection{margin: 5em 0 0}
.subProductList{max-width: 1640px;padding: 0 20px;width: 100%;margin: 0 auto;display: flex;gap: 20px}
.subProductList_titleBox{min-width: 19.06em;width: 19.06em;padding: 3.75em 0;    display: flex;flex-direction: column;justify-content: space-between;min-height: 26.88em;gap: 2.5em}
.subProductList_title{font-size: 2em;font-weight: 700}
.subProductList_text{font-weight: 500;margin: 1.25em 0 0}
.subProductList_allBtn{display: flex;align-items: center;font-weight: 500;gap: 6px;line-height: 1}
.subProductList_itemBox.basicList{width: calc(100% - 19.06em - 20px)}
.subProductList_itemBox .swiper-slide{width:19.06em !important}
.subProductList_itemBox .productItem{width:19.06em}
.subProductList_tapBox{display: flex;flex-direction: column;align-items: flex-start;gap: 10px;margin: 0 0 2.5em}
.subProductList_tap{border: 1px solid #000;border-radius: 100px;padding: 9px 18px;font-weight: 700}
.subProductList_tap.active{border-color: #FF5100;color: #FF5100}
.productItem{width:100%;text-align: left;position: relative;cursor: pointer}
.productItem_info{cursor: pointer;position: relative}
.productItem_info_img{position: relative}
.productItem_img{padding: 0 0 100%;margin: 0 0 16px}
.productItem_captionBox{display: flex;align-items: center;gap: 10px;margin: 0 0 10px}
.productCaption{font-weight: 700;color: #FF9E18;background: rgba(255, 158, 24, 0.1);border-radius: 5px;padding: 5px}
.productCaption.rColor{color: #E9550D;background: rgba(233, 85, 13, 0.1)}
.productItem_name{font-weight: 700;font-size: 1.25em}
.productItem_listCountArea{display: flex;align-items: flex-start;gap: 10px;margin: 10px 0 0}
.productListCountBox{display: flex;gap: 4px;align-items: flex-start}
.productListCount{color: rgba(0, 0, 0, 0.5)}
.productListCountBox>img{width:1.25em}
.productItem_summary{color: rgba(0, 0, 0, 0.5);margin: 0 0 6px}
.productItem_priceBox{margin: 6px 0 0;display: flex;align-items: center;gap: 10px;flex-wrap: wrap}
.productItem_price{font-size: 1.5em;font-weight: 700;color: #FF5100}
.productItem_basicPrice{color: rgba(51, 51, 51, 0.3);font-size: 1.125em;text-decoration: line-through}
.productItem_listReview{margin: 8px 0 0}

.subProductListSlide_notItem{height: 100%;min-height: 350px}

.btn_productItem_listLike{position: absolute;bottom: 16px;right: 16px}
.btn_productItem_listLike img{width:100%}

.productListPage .productListBoxMin{max-height: 100%}
.productListBoxMin{display: flex;flex-wrap: wrap;gap: 2.5em 20px}
.productListBoxMin .productItem{width: calc(33.33% - 13.33px);min-width: calc(33.33% - 13.33px)}

.productListBox{display: flex;flex-wrap: wrap;gap: 2.5em 20px}
.productListBox .productItem{width: calc(25% - 15px);min-width: calc(25% - 15px)}
.productListBox .notItemArea{padding: 60px 0}

.productListPage{max-width: 1320px;padding: 3.13em 20px 6.25em;margin: 0 auto;display: flex;gap: 2.5em;justify-content: space-between;min-height: 60vh}
.productList_fixedsection{width: 17.81em;min-width: 17.81em;padding-bottom: 20px;background: #fff}
.productListPage .productListBoxMin{width: calc(100% - 17.81em - 2.5em);min-width: calc(100% - 17.81em - 2.5em)}
.productListPage .notItemArea{padding: 20px 0;height: calc(100vh - 19.38em)}

.productList_fixedArea{width: 17.81em;min-width: 17.81em}

.listLoading{display: none;align-items: center;justify-content: center;padding: 20px}
.listLoading>img{width: 3.88em;animation: spin 1s infinite}

.filterMoSection{margin: 0 0 20px}
.filterMoSection.tapOnly .filterMoTap::before{display: none}
.filterMoTap{position: relative;display: flex;justify-content: space-between;gap: 20px;margin: 16px 0 0}
.filterMoTap::before{content: "";position: absolute;height: calc(100% - 1px);background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.9) 10%, #FFFFFF 100%);width: 120px;right: 0;top: 0;z-index: 1}
.filterMoTap .tapBox{width: 100%;padding-right: 130px}
.filterMoTap .tapItem{padding: 10px 0 7px}
.basicSelectBox{z-index: 1;position: absolute;right: 0;top: 50%;transform: translateY(-50%)}
.basicSelectItem{background: url(/assets/images/basic/sel.svg) no-repeat right center;padding: 7px 29px 7px 6px;font-size: 13px;font-weight: 700}
.filterMoTitleBox{display: flex;gap: 20px;justify-content: space-between}
.filterMoTitle{font-size: 20px;font-weight: 700}
.filterMoTitle.active{display: none}
.filterMoShBox{display: flex;align-items: center;justify-content: space-between;gap: 10px}
.filterMoShBox.active{width: 100%}
.filterMoShInput{display: none;width: calc(100% - 32px - 10px)}
.filterMoShBox.active .filterMoShInput{display: block}
.btn_filterMoShView{min-width: 32px;height: 32px;display: flex;align-items: center;justify-content: center}

/*filter*/
.filterArea + .filterArea{margin: 2.5em 0 0}
.filterAreaTitle{font-size: 1.38em;font-weight: 700;margin: 0 0 0.82em}
.filterShItem{width: 100%}
.filterShBox{width: 100%;position: relative}
.filterSh{border-bottom: 1px solid #E1E1E2;padding: 4px 35px 4px 0;width: 100%}
.btn_filterSh{position: absolute;right: 0;top: 50%;transform: translateY(-50%)}
.filterTapItem{display: flex;align-items: center;justify-content: space-between;gap: 15px;width: 100%;opacity: 0.3}
.filterTapItem.active{opacity: 1}
.filterTapItem + .filterTapItem{margin: 20px 0 0}
.filterTapName{font-weight: 500}
.filterTapSubData{font-weight: 500}

/*mypage*/
.customPopup_conternts .familyItemAddBtn{min-height: 230px}
.familyItemAddBtn{border: 1px solid #E1E1E2;width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;text-align: center;gap: 13px;border-radius: 8px;font-size: 0.875em;font-weight: 500}
.familyItem{border-radius: 8px;background: #F3F3F3;border: 1px solid transparent;padding: 15px}
.familyItem.active{border: 1px solid #000}
.familyItem_infoBox{display: flex;align-items: flex-start;gap: 8px}
.familyItem_info{width: calc(100% - 28px)}
.familyItem_textBox{display: flex;align-items: center;justify-content: space-between;gap: 10px;width: 100%}
.familyItem_textBox + .familyItem_textBox{margin: 10px 0 0}
.familyItem_textName{color: rgba(0, 0, 0, 0.5);font-size: 13px}
.familyItem_textVal{font-size: 13px;color: rgba(0, 0, 0, 0.5)}
.familyItem_textBox.familyItem_nameBox .familyItem_textName{font-size: 16px;font-weight: 700;color: #000}
.familyItem_textBox.familyItem_nameBox .familyItem_textVal{font-size: 14px;color: #FF5100;font-weight: 600}
.familyItem .pageBtn{margin: 20px 0 0}
.customPopup_conternts .familyItem + .familyItem{margin: 16px 0 0}
.customPopup_conternts .familyItem + .familyItemAddBtn{margin: 16px 0 0}

/*survey*/
.survey_img{width: 100%;max-width: 330px;margin: 0 auto}

.surveyInfoTitleBox{width: 100%;margin: 16px auto 0;border: 0;padding: 0;gap: 15px;display: flex;align-items: center;cursor: auto}
.surveyInfoBox.active .surveyInfoTitleBox{border: 0}
.surveyInfoTextBox{width: calc(100% - 15px - 20px)}
.surveyInfoTitle{font-size: 13px;font-weight: 400;color: #000;line-height: 1.8;width: 100%;text-align: left}
.surveyInfoTextBox + img{min-width: 20px;transition: transform 0.2s, opacity 0.2s;opacity: 0.5}
.surveyInfoBox.active .surveyInfoTextBox + img{transform: rotate(180deg);opacity: 1}
.surveyInfoText{color: #3B3B40;font-size: 13px;line-height: 20px}
.surveyInfoTextItem{padding: 10px 0 0;opacity: 0.7;display: none}

.surveyDetailChart{margin: 0 auto;padding: 0;list-style-type: none;overflow: hidden;position: relative;width: 100%;height: auto;padding: 0 0 50%}
.surveyDetailChart::before{position: absolute;content: '';width: inherit;height: 100%;left: 0;top: 0;box-sizing: border-box;border: 15px solid #EFEFEF;border-bottom: none;border-top-left-radius: 150px;border-top-right-radius: 150px}
.surveyDetailChart span{position: absolute;top: calc(100% + 1px);left: 0;width: inherit;height: 100%;border: 15px solid;border-top: none;border-bottom-left-radius: 150px;border-bottom-right-radius: 150px;transform-origin: 50% 0;transition: transform 1s}
.surveyDetailChartItem{width: calc(25% - 1.875em);min-width: calc(25% - 1.875em);position: relative;opacity: 0.3}
.surveyDetailChartItem.active{opacity: 1}

.surveyDetailChart.gColor span{border-color:#58D764} 
.surveyDetailChart.rColor span{border-color:#FF5100}
.surveyDetailChart.oColor span{border-color:#FF9E18}

.surveyDetailSection{margin: 40px auto 0;max-width: 1320px;padding: 0 20px}
.surveyDetailSection:last-child{padding-bottom: 7.5em}
.surveyDetailSection.surveyDetail_flexSection{display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.surveyDetailSection + .surveyDetailSection{padding-top: 40px;border-top: 1px dashed rgba(0,0,0,0.1)}

.surveyDetailSection.surveyDetail_flexSection .surveyDetailTitleBox{padding: 0 0 2em}
.surveyDetailTitleBox{padding: 2em 0}
.surveyDetailTitle{font-size: 1.5em;font-weight: 700}
.surveyDetailSubTitle{margin: 2px 0 0;color: rgba(0, 0, 0, 0.5)}
.surveyDetailUserInfo{max-width: 600px;width: 100%;display: flex;flex-wrap: wrap;gap: 1.88em}
.surveyDetailUserInfoItem{width: calc(25% - 1.41em);min-width: calc(25% - 1.41em)}
.surveyDetailUserInfoName{color: rgba(0, 0, 0, 0.5)}
.surveyDetailUserInfoVal{font-size: 1.5em;font-weight: 700}

.surveyDetailNutrition{display: flex;flex-wrap: wrap;gap: 1.5em}
.surveyDetailNutritionItem{width: calc(25% - 1.125em);min-width: calc(25% - 1.125em);padding: 10px 0}
.surveyDetailNutrition_nameBox{display: flex;align-items: center;gap: 7px}
.surveyDetailNutrition_nameBox .dataCaption{font-weight: 700}
.surveyDetailNutritionItemName{font-weight: 700;font-size: 1.125em}
.surveyDetailNutritionItemName.oColor{color: #FF9E18}
.surveyDetailNutritionItemName.rColor{color: #EC5049}
.surveyDetailNutritionItemVal{font-size: 2.06em;font-weight: 700}
.surveyDetailNutritionItemVal.oColor{color: #FF9E18}
.surveyDetailNutritionItemVal.rColor{color: #EC5049}
.surveyDetailNutritionItemVal>span{color: rgba(0, 0, 0, 0.5);font-weight: 400;font-size: 0.85em}

.surveyDetailTextBox{background: #f9f9f9;border-radius: 8px;padding: 1.88em;text-align: center;margin: 3.75em 0 0}
.surveyDetailText{font-weight: 500;margin: 8px 0 0}
.surveyDetailText_btnBox{gap: 1.88em;display: flex;justify-content: center;margin: 1.88em auto 0}

.surveyDetailSection .subProductList{padding: 0}

.surveyDetailChartBox{display: flex;margin: 2.5em 0 0;gap: 2.5em;justify-content: space-between}
.surveyDetailChart_info{text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 1em;margin: -33% 0 0}
.surveyDetailChart_infoVal{font-size: 4em;font-weight: 700}
.surveyDetailChart_infoVal.gColor{color:#58D764}
.surveyDetailChart_infoVal.rColor{color:#FF9E18}
.surveyDetailChart_infoVal.oColor{color:#FF5100}
.surveyDetailChart_infoName{font-weight: 700;font-size: 1.06em}
.surveyDetailChart_infoVal span{font-size: 0.47em;font-weight: 400;color:#000}

.surveyDetail_lineChartArea{margin: 2.5em 0 0;overflow-x: auto}
.surveyDetail_lineChartBox{height: 29.38em;min-width: 1280px}
.surveyDetail_lineChartArea::-webkit-scrollbar,
.surveyDetail_lineChartArea::-webkit-scrollbar-thumb {height: 10px;max-width:10px;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent}
.surveyDetail_lineChartArea::-webkit-scrollbar-thumb {box-shadow: inset 0 0 0 4px #939393}
.surveyDetail_lineChartArea::-webkit-scrollbar-track {background-color: #F9F8FA}

/*market*/
.marketMainBanner{margin: 3.13em 0 0;display: flex;justify-content: center}
.marketMainBannerImg{padding: 0 0 40.7%;width: 100%;display: block;border-radius: 1.5em}
.marketMainBannerControll .swiper-button-prev{position: absolute;width: 3.13em;height: 3.13em;background: url(/assets/images/icon/market_banner_prev.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;left: 0}
.marketMainBannerControll .swiper-button-next{position: absolute;width: 3.13em;height: 3.13em;background: url(/assets/images/icon/market_banner_next.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;right: 0}
.marketMainBannerControll{position: absolute;width: 100%;max-width: 1280px;top: 50%;left: 50%;transform: translateX(-50%);z-index: 10}
.marketMainBannerControllBox{position: relative;transform: translateY(-1.56em)}
.marketMainBanner .swiper{width: 202vw;margin: 0 auto;overflow: visible !important}

.marketMainBanner .swiper-pagination{display: flex;gap: 0.75em;align-items: center;justify-content: center;position: absolute;z-index: 10;bottom: 1.88em;left: 50%;transform: translateX(-50%)}
.marketMainBanner .swiper-pagination-bullet{width: 1em;height: 1em;border-radius: 100%;background: rgba(0, 0, 0, 0.5)}
.marketMainBanner .swiper-pagination-bullet-active{background: rgba(255, 255, 255, 0.7)}


.marketMainSection{padding: 6.25em 20px 0;max-width: 1320px;margin: 0 auto}
.marketSectionTitle{font-size: 1.5em;font-weight: 700}
.marketSectionSubTitle{margin: 2px 0 0;color: rgba(0, 0, 0, 0.5)}

.marketSectionTitleBox + .productListBox{margin-top: 2.5em}
.marketSectionTitleArea + .productListBox{margin-top: 2.5em}
.marketMainBtnBox{text-align: center;margin: 3.75em 0 0}

.marketSubBanner{display: flex;gap: 20px}
.marketSubBannerItem{min-height: 31.25em;display: flex;flex-direction: column;gap: 60px;justify-content: space-between;align-items: flex-start;padding: 1.88em;width: calc(25% - 15px);min-width: calc(25% - 15px);cursor: pointer}
.marketSubBannerItem_title{font-size: 1.5em;color: #fff;font-weight: 700}
.marketSubBannerItem_text{margin: 1em 0 0;color: #fff}
.marketSubBannerItem>img{max-width: 9.31em;width: 100%}

.marketMainSection.gBg{width: 100%;max-width: 100%;background: #F9F9F9;padding: 3.75em 0;margin: 6.25em 0 0}
.marketMainSection.gBg .marketMainArea{max-width: 1320px;margin: 0 auto}
.marketMainSection.gBg .subProductListSection {margin: 0}
.marketMainSection.gBg .subProductList{max-width: 100%}
.marketMainSection.gBg .subProductList_itemBox{width: calc(100% - 19.06em - 20px)}

.marketSectionTitleArea{display: flex;align-items: center;justify-content: space-between;gap: 30px}
.marketMain_tapBox{display: flex;gap: 10px;justify-content: flex-end;flex-wrap: wrap}
.marketMain_tap{border: 1px solid #000;border-radius: 100px;padding: 9px 18px;font-weight: 700;opacity: 0.2}
.marketMain_tap.active{opacity: 1}
.marketMain_tap>img{width:5.5em}

/*detail*/
.productDetailInfo_section{max-width: 1320px;margin: 3.13em auto 0;padding: 2.5em 20px;display: flex;gap: 2.5em}
.productDetailInfo_img{width: 31.25em;min-width: 31.25em;height: 31.25em;border-radius: 12px}
.productDetailInfo_area{width: calc(100% - 2.5em - 31.25em)}
.productDetailInfo_captionArea{display: flex;align-items: center;justify-content: space-between;gap: 20px;margin: 0 0 10px}
.productDetailInfo_captionBox{display: flex;align-items: center;gap: 10px}
.detailSubBtnBox{display: flex;justify-content: flex-end;gap: 1em}
.detailSubBtn{font-weight: 700;display: flex;align-items: center;gap: 8px;justify-content: center;border: 1px solid #000;border-radius: 100px;padding: 10px 12px}
.detailSubBtn.oColor{color: #FF5100;border-color: #FF5100}
.productDetailInfo_productText + .detailSubBtn{margin: 3.13em 0 0}
.productDetailInfo_countArea + .detailSubBtn{margin: 3.13em 0 0}
.productDetailInfo_priceBox + .detailSubBtn{margin: 3.13em 0 0}
.productDetailInfo_name{font-size: 1.5em;font-weight: 700;max-width: 60%}
.productDetailInfo_productText{margin: 16px 0 0;line-height: 1.5}
.productDetailInfo_review{margin: 20px 0 0}
.productDetailInfo_priceBox{display: flex;align-items: center;gap: 10px;margin: 20px 0 0}
.productDetailArea{border-top: 1px dashed rgba(0, 0, 0, 0.1);margin: 2.5em 0 0}
.productDetailArea + .productDetailArea{margin: 0}
.productDetailArea.paddingItem{padding: 2.5em 0}
.productDetailInfo_subInfoTitleBox{display: flex;align-items: center;justify-content: space-between;gap: 10px;width: 100%;padding: 2.5em 0;text-align: left}
.productDetailInfo_subInfoTitle{font-size: 1.125em;font-weight: 700}
.productDetailInfo_subInfoTitleBox>img{transition: transform 0.2s}
.productDetailInfo_subInfoBox.active .productDetailInfo_subInfoTitleBox>img{transform: rotate(180deg)}
.productDetailInfo_subInfoTextItem{display: none;padding: 0 0 2.5em}
.productDetailInfo_subInfoTextBox{width: 100%}
.productDetailInfo_subTextBox{display: flex;align-items: flex-start;gap: 20px}
.productDetailInfo_subTextBox + .productDetailInfo_subTextBox{margin: 14px 0 0}
.productDetailInfo_subTextBox.paddingItem{margin: 0;padding: 2.5em 0 0}
.productDetailInfo_subText{font-size: 0.875em;font-weight: 700;min-width: 7.14em;width: 7.14em}
.productDetailInfo_subVal{font-size: 0.875em}
.productDetailInfo_totalPriceBox{display: flex;align-items: flex-end;justify-content: space-between;gap: 20px;width: 100%}
.productDetailInfo_totalPriceName{font-size: 0.875em;margin: 0 0 3px}
.productDetailInfo_totalPriceItem{text-align: right}
.productDetailInfo_totalBasicPrice{opacity: 0.3;text-decoration: line-through;font-size: 1.125em;margin: 0 0 6px}
.productDetailInfo_totalPrice{color: #FF5100;font-weight: 700;font-size: 1.5em}

.optiopn_controllBox{width: 100%}
.productOptionSel .inputItem{border-radius: 6px;border: 1px solid #000;padding: 10px 15px}
.productOptionSel .inputItem::placeholder{color:#000}
.productOptionSelBox{margin: 20px 0 0}
.optionSelItem{margin: 10px 0 0;border-radius: 6px;border: 1px solid #000;width: 100%;padding: 12px 15px;display: flex;flex-direction: column;gap: 10px}
.optionSelItem_nameBox{display: flex;align-items: center;justify-content: space-between;width: 100%;gap: 20px}
.optionSelItem_name{font-weight: 700}
.optionSelItem_name span{font-weight: 400}
.optionSelItem_controll{display: flex;align-items: center;justify-content: space-between;gap: 20px}
.countBox{display: flex;border: 1px solid #E8E8E8;min-width: 92px;width: 92px}
.btn_m{width: 25px;height: 25px;display: flex;align-items: center;justify-content: center;min-width: 25px}
.countInput{border-right: 1px solid #E8E8E8;border-left: 1px solid #E8E8E8;text-align: center;max-width: 40px;background: #fff !important;color: #000 !important;font-size: 13px}
.btn_p{width: 25px;height: 25px;display: flex;align-items: center;justify-content: center;min-width: 25px}
.optionSelItem_priceBox{display: flex;justify-content: flex-end;text-align: right;gap: 10px;align-items: flex-end}
.optionSelItem_price{font-size: 1.25em;font-weight: 700;color: #FF5100}
.optionSelItem_basicPrice{opacity: 0.3;text-decoration: line-through}

.inputItemBox + .dotTextBox{margin: 8px 0 0}
.detailSection{max-width: 1280px;margin: 50px auto 0;padding: 0 20px}
.detailSection + .detailSection{border-top: 1px dashed rgba(0, 0, 0, 0.1);padding-top: 50px}
.detailSection:last-child{padding-bottom: 80px}

.detailCategoriesSection{display: flex;gap: 2.5em;flex-wrap: wrap}
.detailCategoriesArea{width: calc(50% - 1.25em);min-width: calc(50% - 1.25em)}
.detailCategoriesSection.row3 .detailCategoriesArea{width: calc(33.325% - 1.66em);min-width: calc(33.325% - 1.66em)}
.detailCategoriesTitle{font-size: 1.125em;font-weight: 700}
.detailCategoriesItemBox{margin: 1.25em 0 0}
.detailCategoriesItem{display: flex;justify-content: space-between;width: 100%;margin: 6px 0 0}
.detailCategoriesItem_name{font-weight: 500}
.detailSection .surveyDetailTitleBox{padding-top: 0}

.detailMenus_subInfoTitleBox{display: flex;align-items: center;justify-content: space-between;gap: 10px;width: 100%;padding: 2.5em 0;text-align: left}
.detailMenus_subInfoTitleBox>img{transition: transform 0.2s;width:1.7em;min-width: 1.7em}
.detailMenusSection.active .detailMenus_subInfoTitleBox>img{transform: rotate(180deg)}
.detailMenus_subInfoTextItem{display: none;padding: 0 0 2.5em}
.detailMenus_subInfoTextBox{width: 100%}
.detailMenusSection + .detailMenusSection{margin-top: 3.13em 0 0}

.detailTapBox{padding: 6.25em 20px 0;margin: 0 auto;max-width: 1320px}

.fixedTapSection{width: 100%;left: 0;background: #fff}
.fixedTapSection .tapBox{padding: 0 20px}
.fixedTapPoint{min-height: 3.38em;width:100%}

.detailBottomInfoSection{border-top: 1px solid #E3E3E3;background: #fff;position: fixed;width: 100%;left: 0;bottom: 0;z-index: 10;display: none}
.detailBottomInfoSection.active{display: block}
.detailBottomInfoArea{max-width: 1640px;margin: 0 auto;padding: 1.25em 20px;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.detailBottomInfo_name{font-size: 1.25em;font-weight: 700}
.productDetailInfo_priceBox {margin: 2px 0 0}
.productItem_price{font-size: 1.25em}
.productItem_basicPrice{font-size: 0.875em}
.detailBottomInfoArea .pageBtn{width: 12.50em}
.detailBottomInfoArea .productDetailInfo_captionBox{margin: 0 0 8px;font-size: 0.875em}
.detailBottomInfoArea .productCaption{padding: 3px 4px}

.productDetailInfo_countArea{margin: 16px 0 0;display: flex;align-items: center;gap: 10px}

.recipeDetailSection{display: flex;flex-wrap: wrap;gap: 3.75em 2.5em}
.recipeDetailItem{width: calc(50% - 1.25em);display: flex;gap: 2.5em;align-items: flex-start}
.recipeDetailItem_img{width: 12.5em;min-width: 12.5em;height: 12.5em;border-radius: 24px;background-color: #f1f1f1}
.recipeDetailItem_textArea{width: calc(100% - 12.5em - 2.5em)}
.recipeDetailItem_step{font-size: 1.125em;font-weight: 700}
.recipeDetailItem_text{font-size: 14px;margin: 7px 0 0;line-height: 1.6}
.recipeDetailItem_imgSelBox{display: flex;gap: 14px;flex-wrap: wrap;margin: 1.88em 0 0}
.recipeDetailItem_imgSel{background-color: #f1f1f1;width: 3.75em;height: 3.75em;border-radius: 12px;opacity: 0.2;cursor: pointer}
.recipeDetailItem_imgSel.active{opacity: 1}

/*order*/
.orderPage{padding: 3.13em 20px 6.25em;max-width: 1320px;margin: 0 auto;display: flex;justify-content: space-between;gap: 2.5em}
.orderSection{width: calc(100% - 2.5em - 285px)}
.tableTopBtnBox{display: flex;align-items: center;justify-content: space-between;gap: 20px;margin: 0 0 20px}
.chk_item.cartAll label{width: 100%}
.tableTapBtn{font-weight: 500;color: #A0A0A0;text-decoration: underline}

.tableSection{border-top: 1px solid #000;border-bottom: 1px solid rgba(0, 0, 0, 0.1)}
.table_head{display: flex;border-bottom: 1px solid rgba(0, 0, 0, 0.1)}
.table_th{font-size: 14px;font-weight: 700;display: flex;align-items: center;justify-content: center;gap: 10px;padding: 10px 5px;text-align: center}
.table_th_contents{width:100%;font-weight: 700;font-size: 1em}
.tableTextItem{background: rgba(217, 217, 217, 0.2);color: rgba(59, 59, 64, 0.6);font-weight: 600;font-size: 14px;padding: 10px 20px;border-bottom: 1px solid rgba(0, 0, 0, 0.1)}
.table_tr{display: flex}
.table_tr + .table_tr{border-top: 1px dashed rgba(0, 0, 0, 0.1)}
.table_tr + .tableTextItem{border-top: 1px solid rgba(0, 0, 0, 0.1)}
.table_td{display: flex;align-items: center;justify-content: center;text-align: center;padding: 1.88em 5px}
.table_td.alignLeft{justify-content: flex-start;text-align: left}
.table_tr.notRemainAmount{opacity: 0.3}
.tableBasicTextItem{font-size: 14px;font-weight: 500;width: 100%}
.tableMinBtn{display: block;background: #000;color: #fff;border-radius: 6px;border: 1px solid #000;font-size: 14px;font-weight: 600;padding: 4px 2px;min-width: 87px;letter-spacing: -0.08em}

.order_fixedsection,.order_fixedArea{width: 285px;min-width: 285px}
.order_fixedArea{padding-bottom: 20px;background: #fff}
.orderFixedItemBox + .orderFixedItemBox{margin: 2.5em 0 0}
.orderFixedSubItemBox + .orderFixedItemBox{margin: 2.5em 0 0}
.orderFixedItemBox_name{font-size: 1.38em;font-weight: 700;padding: 0 0 0.91em;margin: 0 0 0.91em;border-bottom: 1px solid #000}
.orderFixedItemTextBox{display: flex;align-items: center;justify-content: space-between;gap: 15px;margin: 10px 0 0}
.orderFixedItemTextName{font-weight: 500}
.orderFixedSubItemBox{border-top: 1px solid rgba(0, 0, 0, 0.1);margin: 1.25em 0 0;padding: 1.25em 0 0}
.orderBtnBox{margin: 20px 0 0}
.orderProductItem{display: flex;gap: 20px;align-items: center}
.orderProductItem_img{width: 6.25em;min-width: 6.25em;height: 6.25em}
.orderProductItem_name{font-weight: 700}
.orderProductItem_options{color: rgba(0, 0, 0, 0.5);font-size: 0.875em;margin: 6px 0 0}
.orderProductItem_priceListItem{margin: 2px 0 0;font-size: 0.875em}
.orderProductItem_price,.notRemainAmountText{font-weight: 700;font-size: 1.25em}
.orderProductItem_basicPrice{margin: 6px 0 0;color: rgba(0, 0, 0, 0.3);text-decoration: line-through}

.notItemArea.cart_notItems{padding: 9.38em 20px}

.orderArea_titleBox{padding: 0 0 1.25em;margin: 0 0 1.25em;border-bottom: 1px solid #000;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.orderArea_titleBox.notBorder{border-bottom:0;margin: 0}
.orderArea_title{font-size: 1.38em;font-weight: 700}
.orderArea + .orderArea{margin: 3.13em 0 0}
.orderArea_title_subItemBox{display: flex;align-items: center;justify-content: flex-end;gap: 20px}
.orderArea_btn{font-weight: 500;text-decoration: underline}
.orderArea input:read-only{color: #000;background: #fff;cursor: pointer}
.orderArea input:disabled{cursor: auto;color: var(--disabledColor);background: var(--disabledBg)}
.orderTermsBox{margin: 2.5em 0 0}
.orderTermsTextBox{display: flex;align-items: center;justify-content: space-between;gap: 20px}
.orderTermsTextBox + .orderTermsTextBox{margin: 5px 0 0}
.orderTermsTextBox .chkBoxBtn{cursor: pointer}
.orderTermsTextItem{display: flex;align-items: center}
.orderTermsTextItem span{width: 3px;height: 3px;display: block;border-radius: 100%;background: #000;margin: 0 7px}
.orderTermsText{font-weight: 500;font-size: 13px}
.orderTerms_caption{color: rgba(0, 0, 0, 0.5);font-size: 13px;font-weight: 700;margin: 10px 0 0}

.cardItemBox{min-height: calc(100vh - 55px - 54px);display: flex;align-items: center;justify-content: center;padding: 40px 20px}
.cardItem{border-radius: 15px;width: 180px;height: 119px;background: #3C3B4B;padding: 14px;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between}
.cardItem.notCard{background: #3C3B4B}
.cardItem.bc{background: #FF6666}
.cardItem.hana{background: #00AAAA}
.cardItem.hyundai{background: #3399FF}
.cardItem.kb{background: #F7CE46}
.cardItem.lotte{background: #DD4444}
.cardItem.nh{background: #66BB44}
.cardItem.samsung{background: #0066AA}
.cardItem.shinhan{background: #004488}

.cardItem_head{display: flex;align-items: flex-start;gap: 10px;justify-content: space-between;width: 100%}
.cardName{color: #fff;font-size: 13px;font-weight: 700}
.cardName>img{max-height: 17px}
.cardSimImg{margin: 13px 0 0 6px;width: 19px}
.cardNumber{width: 100%;text-align: right;font-size: 13px;font-weight: 700;color: #fff}
.cardDel{min-width: 13px}
.cardItem_topInfo{width:100%}

.cardAddBtn{border-radius: 15px;width: 180px;height: 119px;text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 6px;border: 1px solid #000;font-size: 13px;font-weight: 700;margin: 0 auto}
.cardAddBtn img{width: 9px}

.swiper-slide .cardItem{opacity: 0.3;margin: 0 auto}
.swiper-slide-active .cardItem{opacity: 1}

.orderCardSlide{max-width: 390px;margin: 0 auto}

.cardSlideControll .swiper-button-prev{position: absolute;width: 2.5em;height: 2.5em;background: url(/assets/images/icon/market_banner_prev.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;left: 0}
.cardSlideControll .swiper-button-next{position: absolute;width: 2.5em;height: 2.5em;background: url(/assets/images/icon/market_banner_next.svg) no-repeat center;background-size: 100%;top: 0;z-index: 10;right: 0}
.cardSlideControll{position: absolute;width: 100%;max-width: 390px;top: 50%;left: 50%;transform: translateX(-50%);z-index: 10}
.cardSlideControllBox{position: relative;transform: translateY(-1.25em)}

/*subInfoTextArea*/
.subInfoTextArea{border-radius: 12px;background: #f9f9f9;margin: 20px 0 0;padding: 1.88em}
.subInfoTextTitle{font-size: 1.125em;font-weight: 700;margin: 0 0 1.11em}
.subInfoTextText{font-size: 0.875em;line-height: 1.6}
.subInfoText{white-space: pre-wrap;line-height: 1.6}
.subInfoTextArea.subInfoTextArea_img{display: flex;align-items: center;gap: 20px}
.subInfoTextArea.subInfoTextArea_img>img{width: 8.94em;min-width: 8.94em}

.subInfoTextSection{margin: 2.5em 0 0}
.subInfoTextSection .subInfoTextArea{margin: 0}
.subInfoTextSection.row2{display: flex;justify-content: space-between;gap: 20px}
.subInfoTextSection.row2 .subInfoTextArea{width:calc(50% - 10px);min-width:calc(50% - 10px)}

.subInfoText_valueItem{display: flex;align-items: flex-start;justify-content: space-between;gap: 15px}
.subInfoText_valueItem + .subInfoText_valueItem{margin: 8px 0 0}
.subInfoText_valueItem_name,.subInfoText_valueItem_val{font-size: 14px;font-weight: 500}

/*nutrition*/
.textBtnItemMFull{font-size: 13px;font-weight: 700;text-decoration: underline}
.textBtnItemMFull.rColor{color: #E9550D}
.textBtnItemMFull.oColor{color: #FF9E18}

.tableTextValItem{display: flex;align-items: flex-start;justify-content: space-between;gap: 15px;max-width: 100%}
.tableTextValItem.mName{text-align: center}
.tableTextValItem_name{font-size: 13px}
.tableTextValItem_val{font-size: 13px}

.tableSection + .terms_title{margin: 60px 0 0;padding: 60px 0 0;border-top: 1px dashed rgba(0, 0, 0, 0.1)}
.nutritionSurvey_table{margin: 1.88em auto 0}

.nutritionSurvey_slideList{margin: 1.88em auto 0;width: 100vw;transform: translateX(-20px)}
.nutritionSurvey_slideList .swiper{padding: 0 20px}
.surveyListItmeBox{border: 1px solid #ECECEC;border-radius: 12px;padding: 16px}
.table_tr{padding: 0 !important}

.surveyTypeBtn{text-align: left;border: 1px solid #E1E1E2;border-radius: 8px;padding: 13px 20px;display: block;width:100%}
.surveyTypeBtn + .surveyTypeBtn{margin: 10px 0 0}
.surveyTypeBtn.active{border-color: #000}
.surveyTypeBtn_name{font-size: 14px;font-weight: 700}
.surveyTypeBtn_text{font-size: 14px;font-weight: 500;margin: 6px 0 0}

.surveySelect_optionBox{width: calc(100% - 2px);border: 1px solid #E1E1E2;border-radius: 5px;margin: 5px 0 0;background: #fff;overflow: hidden;display: none;max-height: 200px;overflow-y: auto}
.surveySelect_optionBox.active{display: block}
.surveySelect_option{font-size:13px;display: block;color: #0D0D0E;width: 100%;text-align: left;padding: 10px}
.surveySelect_option.active{font-weight: 600}

.surveySelectInputBox{position: relative}
.surveySelectInputBox .inputItem{background: #fff;color: var(--mainTextColor);padding-right: 34px;border-bottom: 1px solid #E1E1E2}
.surveySelectInputBox .inputItem:disabled{color: var(--disabledColor);background: var(--disabledBg)}
.btn_inputSh{position: absolute;right: 0;top: 50%;transform: translateY(-50%)}
.surveySelectSelItemArea{margin: 20px 0 0}
.surveySelectSelItem{border: 1px solid #000;border-radius: 6px;display: flex;gap: 20px;justify-content: space-between;align-items: center;padding: 15px 20px}
.surveySelectSelItem + .surveySelectSelItem{margin: 10px 0 0}
.surveySelectSelItemName{font-weight: 700}
.surveySelectSelItem_controllBox{display: flex;justify-content: flex-end;align-items: center;gap: 20px}

.surveyDetail_productDetailArea  .productDetailInfo_section{margin: 0 auto;padding: 1.88em 0 0}
.surveyDetail_btnBox{max-width: 770px;text-align: center;margin: 4.38em auto 0}

.surveyDetailConsulting{margin: 2.5em 0 0}
.surveyDetailConsultingItem{margin: 1.88em 0 0;width: 100%;display: flex}
.surveyDetailConsultingItem.answer{justify-content: flex-end}
.surveyDetailConsultingItemBox{max-width: 404px;width: 100%}
.surveyDetailConsulting_contents{background: #F1F1F1;border-radius: 12px;padding: 11px 12px}
.surveyDetailConsultingItem.question .surveyDetailConsulting_contents{display: flex;gap: 10px;border-top-left-radius: 2px}
.surveyDetailConsultingItem.answer .surveyDetailConsulting_contents{background: #FF5100;border-top-right-radius: 2px}
.surveyDetailConsulting_contents>img{min-width: 24px;width: 24px}
.surveyDetailConsulting_text{width: 100%}
.surveyDetailConsultingItem.question .surveyDetailConsulting_text{padding-top: 3px}
.surveyDetailConsultingItem.answer .surveyDetailConsulting_text{color: #fff}
.surveyDetailConsulting_header{margin: 0 0 6px;display: flex;align-items: center;gap: 6px}
.surveyDetailConsulting_header_name{font-size: 14px;color: #FF5100;font-weight: 700}

/*mypage*/
.mypage_contents{max-width: 1320px;padding: 3.13em 20px 6.25em;margin: 0 auto;display: flex;gap: 2.5em;justify-content: space-between}
.mypage_section{width: calc(100% - 17.81em - 2.5em);min-width: calc(100% - 17.81em - 2.5em);min-height: 70vh}
.mypage_area + .mypage_area{margin-top: 3.13em}

.mypageArea_titleBox{padding: 0 0 1.25em;margin: 0 0 1.25em;border-bottom: 1px solid #000;display: flex;align-items: center;justify-content: space-between;gap: 20px}
.mypageArea_titleBox.notBorder{border-bottom:0;margin: 0}
.mypageArea_title{font-size: 1.38em;font-weight: 700}
.mypageArea_title_subItemBox{display: flex;align-items: center;justify-content: flex-end;gap: 20px}

.mypageArea_titleBox.minTitle{padding: 0 0 14px;margin: 0 0 14px}
.mypageArea_titleBox.minTitle .mypageArea_title{font-size: 16px}
.inputItemBox + .mypageArea_titleBox.minTitle{margin: 20px 0 0}

.notItemArea.orderTable_notItems{padding: 7em 20px}
.mypage_fixedsection{width: 17.81em;min-width: 17.81em;padding-bottom: 20px;background: #fff}
.mypage_fixedArea{width: 17.81em;min-width: 17.81em}

.mypage_contents .filterMoTap{width: 100vw;margin-left: -20px}
.mypageOrderProductBox{width:100%}

.mypageOrderProductBox .orderProductItem_img{width: 50px;min-width: 50px;height: 50px}
.mypageOrderProductBox .orderProductItem{gap: 10px}
.mypageOrderProductBox .orderProductItem_name{font-size: 14px}
.mypageOrderProductBox .orderProductItem_options{font-size: 12px;margin: 2px 0 0}
.mypageOrderProductBox .orderProductItem_priceListItem{margin: 2px 0 0;font-size: 14px}
.mypageOrder_flexItemBox{display: flex;justify-content: space-between;gap: 15px;width: 100%}
.mypageOrderTableMBtn{width: 100%;display: block;border-radius: 6px;background: #fff;color: #000;border: 1px solid #000;font-size: 13px;font-weight: 700;padding: 10px}
.mypageOrderTableMBtn.oColor{background: rgba(255, 81, 0, 0.1);border-color: rgba(255, 81, 0, 0.1);color: #FF5100}
.mypageOrderTableMBtn + .mypageOrderTableMBtn{margin: 10px 0 0}
.mypageOrderBtnBox{width:100%}
.mypageOrder_flexItemSubBox{display: flex;flex-direction: column;gap: 5px;align-items: flex-end}

.refundCustomPopup input:read-only{color: #000;background: #fff;cursor: pointer}

.inputBox.textType input:read-only,.inputBox.textType input:disabled{color: #000;background: #fff}

.mypage_full_section{width:100%}

.myOrderPaymentInfo{border-top: 1px solid #000;border-bottom: 1px solid #000}

.myOrderPaymentInfoTextSection{display: flex}
.myOrderPaymentInfoTextSection + .myOrderPaymentInfoTextSection{border-top: 1px solid #E1E1E2}

.myOrderPaymentInfoTextArea.myOrderPaymentInfoTextCaption img{width: 100%}
.myOrderPaymentInfoTextArea.myOrderPaymentInfoTextCaption{padding: 0;display: flex;align-items: center;justify-content: center;width: 2.25em;min-width: 2.25em}
.myOrderPaymentInfoTextArea{padding: 20px;width: calc(33.33% - 1.5em);min-width: calc(33.33% - 1.5em)}

.myOrderPaymentInfoTextBox{display: flex;align-items: center;justify-content: space-between;gap: 15px}
.myOrderPaymentInfoText_name{font-size: 13px}
.myOrderPaymentInfoText_val{font-size: 14px}

.myOrderPaymentInfoTextBox + .myOrderPaymentInfoTextBox{margin: 10px 0 0}

.myOrderPaymentInfoTextBox.paymentMainTextBox .myOrderPaymentInfoText_name{font-size: 17px}
.myOrderPaymentInfoTextBox.paymentMainTextBox .myOrderPaymentInfoText_val{font-size: 18px}

.mypageBtnBox{max-width: 770px;text-align: center;margin: 4.38em auto 0}
.mypageBtnBox .pageBtn_box{justify-content: center}
.mypageList_notItems{min-height: 70vh}

.withdrawalInfoBox{margin: 25px 0 0}
.withdrawalInfoBox .dotText{font-size: 12px} 

.familyList{display: flex;flex-wrap: wrap;gap: 20px}
.familyList .familyItem{width: calc(33.33% - 13.33px);min-width: calc(33.33% - 13.33px)}
.familyList .familyItemAddBtn{width: calc(33.33% - 13.33px);min-width: calc(33.33% - 13.33px);min-height: 299px}

.popupInputBox + .textBtnItemBox{margin: 20px 0 0}

.ingredientKeySection{padding: 1.56em 2.5em;border-radius: 8px;border: 1px solid #000}
.ingredientKey_title{font-size: 1.38em;font-weight: 700}
.ingredientKey_textBox{margin: 12px 0 12px;opacity: 0.5}
.ingredientKey_inputBox{position: relative}
.ingredientKey_inputBox .inputItem{padding-right: 35px}
.btn_ingredientKey_sh{position: absolute;right: 0;top: 50%;transform: translateY(-50%)}

.ingredientType_btnBox{display: flex;gap: 6px}
.ingredientType{display: flex;align-items: center;justify-content: center;gap: 6px;border-radius: 5px;background: rgba(0, 0, 0, 0.1);padding: 3px 7px}
.ingredientType.gColor{background: rgba(51, 173, 55, 0.1);color: #33AD37}
.ingredientType.rColor{background: rgba(236, 80, 73, 0.1);color: #EC5049}
.ingredientType_text{font-size: 12px;font-weight: 700}
.ingredientType.gColor .ingredientType_text{color: #33AD37}
.ingredientType.rColor .ingredientType_text{color: #EC5049}

.ingredientTr .tableBasicTextItem.pcContents{font-size: 12px}
.ingredientTr .tableTextValItem_val,.ingredientTr .tableTextValItem_name{font-size: 12px}

.tapBox.pcType{border: 0;gap: 20px;margin: 0 0 8px}
.tapBox.pcType .tapItem.active{border-color: transparent !important;opacity: 1}
.tapBox.pcType .tapItem{font-size: 1.38em;color: #000;opacity: 0.3;font-weight: 700}

.myMenu_contentsBox{max-width: 1220px;margin: 0 auto;padding: 3.75em 20px 100px}
.myMenu_title{font-size: 1.38em;font-weight: 700}
.myMenu_date{font-size: 0.875em;color: #3B3B40;margin: 4px 0 0}
.myMenu_contetns{margin: 1.88em auto 0}

/*반응형*/
@media (min-width:1025px){
  .mypageOrderTableTr .table_td{flex-direction: column;gap: 10px}

  .myMenuTr .table_td{padding: 15px 5px}
}
@media (min-width:961px){
  .pageBtn_box.pcBasicBtnBox{position: static;margin: 40px 0 0;padding: 0}

  .pageBtn_box.pcFixedBtnBox{background: transparent;width: auto;left: 50%;transform: translateX(-50%)}
  .pageBtn_box.pcFixedBtnBox .pageBtn{box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);min-width: 320px}

  .fixedTapPoint .tapBox{gap: 0;overflow: unset;justify-content: space-between}
  .fixedTapPoint .tapItem{width: 100%;font-size: 1em;padding: 0.875em 5px}
  .fixedTapPoint .tapItem:first-child:nth-last-child(2),.fixedTapPoint .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
  .fixedTapPoint .tapItem:first-child:nth-last-child(3),.fixedTapPoint .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
  .fixedTapPoint .tapItem:first-child:nth-last-child(4),.fixedTapPoint .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}
  .fixedTapPoint .tapItem:first-child:nth-last-child(4),.fixedTapPoint .tapItem:first-child:nth-last-child(5) ~ .tapItem{width: 20%}
}

@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1640px){
	.subProductList_itemBox .swiper-slide{width:18em !important}
  .subProductList_itemBox .productItem{width:18em}
}
@media (max-width:1600px){
    html, body{font-size:15px}
}
@media (max-width:1480px){
	.subProductList_itemBox .swiper-slide{width:17em !important}
  .subProductList_itemBox .productItem{width:17em}
}
@media (max-width:1440px){
    html, body{font-size:14px}
}
@media (max-width:1366px){
    html, body{font-size:13px}
}
@media (max-width:1280px){
    html, body{font-size:12px}

    /* .marketMainBanner .swiper{max-width: calc(100% - 24px)} */
}
@media (max-width:1152px){
    html, body{font-size:11px}
}
@media (max-width:1080px){
	.subProductList_itemBox .swiper-slide{width:16em !important}
  .subProductList_itemBox .productItem{width:16em}
}
@media (max-width:1024px){
    html, body{font-size:13px}

    .contentsSection.headerContents,.contentsSection.headerBannerContents{padding-top: 54px}
    .contentsSection.mBtnContents{padding-bottom: 90px}
    .contentsSection.mBtnContentsR2{padding-bottom: 150px}
    
    .pcheader{display: none}
    .mheader{display: block}
    .headerSection{height: 100%;justify-content: center;padding: 0 64px}

    .termsCustomPopup .popupSetion{max-width: 100%;min-height: 0}

    /*main*/
    .mainDataSection{flex-direction: column}
    .mainDataArea.row1,.mainDataArea.row3{width:100%}
    .mainDataArea.row4{padding: 30px}
    .mainDataInfo{display: none}

    .mContents{display: block !important}
    .mContents.flexItem{display: flex !important}
    .mContents.inlineFlexItem{display: inline-flex !important}
    .pcContents{display: none !important}

    .mainDataArea{padding: 16px}

    .mainDataHealthInfo{flex-wrap: wrap;gap: 12px}
    .mainDataHealthInfoItem{width: calc(50% - 6px);flex-direction: column;align-items: flex-start;justify-content: center;padding: 15px 12px}
    .mainDataHealthInfoItem>img{width: 32px}
    .mainDataHealthInfoItem_val{margin: 9px 0 0}
    .mainDataHealthInfoBtnBox{text-align: center;margin: 20px 0 0}

    .mainSubBannerImg{height: 160px}
    .mainSubBanner{margin: 40px 0 0}

    /*product*/
    .subProductListSection{margin: 40px 0 0}
    .subProductList{gap: 30px;flex-direction: column}
    .subProductList_titleBox{padding: 0;min-height: 0;gap: 12px;width: 100%;min-width: 0}
    .subProductList_title{font-size: 24px}
    .subProductList_text{margin: 12px 0 0}
    .subProductList_tapBox{margin: 30px 0 0;flex-direction: row;gap: 0;overflow-x: auto;width: 100%;border-bottom: 1px solid rgba(0, 0, 0, 0.3)}
    .subProductList_tap{border: 0;border-bottom: 1px solid transparent;border-radius: 0;color: rgba(0, 0, 0, 0.3);padding: 9px 25px;transform: translateY(1px);white-space: nowrap}

    .subProductList_itemBox .swiper-slide{width:auto !important}
    .subProductList_itemBox .productItem{width:160px}

    .productItem_captionBox{gap: 4px;margin: 0 0 6px}
    .productItem_img{margin: 0 0 12px}
    .productCaption{font-size: 11px;padding: 3px 4px}
    .productItem_name{font-size: 13px}
    .productItem_listCountArea{margin: 7px 0 0}

    .subProductList_notItem .subProductList_itemBox{min-width: 0;width: 100%}

    .productItem_summary{margin: 0 0 4px}
    .productItem_priceBox{margin: 4px 0 0}
    .productItem_price{font-size: 16px}
    .productItem_basicPrice{font-size: 14px}

    .productItem_listReview{margin: 4px 0 0}

    .productListBoxMin .productItem{width: calc(50% - 6px);min-width: calc(50% - 6px)}
    .productListBoxMin{gap: 20px 12px}

    .productListBox{gap: 20px 12px}
    .productListBox .productItem{width: calc(50% - 6px);min-width: calc(50% - 6px)}

    .productListPage{display: block;padding-top: 30px}
    .productListPage .productListBoxMin{width:100%;min-width: 0}

    .productDetailInfo_name{max-width:100%}

    .subProductListSlide_notItem{height: 260px}

    .subProductList_itemBox.basicList{width: 100%}

    /*survey*/
    .surveyDetailSection{margin: 20px auto 0;max-width: 1320px;padding: 20px}
    .surveyDetailSection:last-child{padding-bottom: 80px}
    .surveyDetailSection + .surveyDetailSection{padding-top: 20px}
    .surveyDetailTextBox{padding: 16px;margin: 25px 0 0}
    .surveyDetailText_btnBox{margin: 30px auto 0;flex-direction: column;gap: 12px;max-width: 240px}

    .surveyDetailNutrition{gap: 12px}
    .surveyDetailNutritionItem{width: calc(50% - 6px);min-width: calc(50% - 6px);text-align: center;padding: 20px 0}
    .surveyDetailNutrition_nameBox{justify-content: center;gap: 4px}
    .surveyDetailNutritionItemName{font-size: 14px}
    .surveyDetailNutritionItemVal{font-size: 24px}
    .surveyDetailNutritionItemVal>span{font-size: 20px}

    .surveyDetailSection.surveyDetail_flexSection{flex-direction: column;gap:10px}
    .surveyDetailTitleBox{width: 100%;padding: 16px 0}
    .surveyDetailUserInfo{justify-content: flex-start;gap: 10px;text-align: center;max-width: 100%}
    .surveyDetailUserInfoItem{width: calc(33.33% - 6.66px);min-width: calc(33.33% - 6.66px)}

    .surveyDetailChartBox{gap: 20px;margin: 10px 0 0;flex-wrap: wrap}
    .surveyDetailChartItem{width: calc(25% - 15px);min-width: calc(25% - 15px)}
    .surveyDetailChart{display: none}
    .surveyDetailChart_info{margin: 0;display: block}
    .surveyDetailChart_info .dataCaption{display: inline}
    .surveyDetailChart_infoVal{font-size: 18px;margin: 4px 0 0}
    .surveyDetailChart_infoVal span{font-size: 11px}
    .surveyDetailChart_infoName{font-size: 12px;margin: 4px 0 0}

    /*market*/
    .marketMainBanner{margin: 20px 0 0}
    .marketMainBannerImg{height: 300px}

    .marketMainBanner .swiper{width: 280vw}

    .marketSectionTitleBox + .productListBox{margin-top: 30px}
    .marketMainBtnBox{margin: 30px 0 0}

    .marketMainSection{padding: 40px 20px 0}
    .marketSubBanner{flex-wrap: wrap;gap: 12px}
    .marketSubBannerItem{width: calc(50% - 6px);min-width: calc(50% - 6px);min-height: 200px;padding: 12px}
    .marketSubBannerItem_title{font-size: 14px}
    .marketSubBannerItem_text{font-size: 11px}
    .marketSubBannerItem>img{max-width: 70px}

    .marketMainSection.gBg{margin: 40px 0 0;padding: 40px 0}
    .marketMainSection.gBg .subProductList_itemBox{width: 100%}

    .marketSectionTitleArea{display: block}
    .marketMain_tapBox{margin: 30px 0 0;flex-direction: row;gap: 0;overflow-x: auto;width: 100%;border-bottom: 1px solid rgba(0, 0, 0, 0.3);justify-content: flex-start;flex-wrap: nowrap;border: 0}
    .marketMain_tap{border: 0;border-bottom: 1px solid rgba(0, 0, 0, 0.3);border-radius: 0;color: rgba(0, 0, 0, 0.3);padding: 9px 25px;white-space: nowrap;opacity: 1}
    .marketMain_tap.active{border-color: #FF5100;color: #FF5100}

    .toastPopup .productDetailInfo_subTextBox{flex-direction: column;gap: 8px}
    .toastPopup .productDetailInfo_subText{font-size: 13px}
    .toastPopup .dotText{font-size: 12px}
    .toastPopup .productDetailInfo_subTextBox.paddingItem{padding: 14px 0 0}
    .optionSelItem_name span{display: block}

    .detailSection{margin: 30px auto 0}
    .detailSection + .detailSection{padding-top: 30px}
    .detailCategoriesSection{flex-direction: column;gap: 20px}
    .detailCategoriesArea{width: 100%;min-width:0}
    .detailCategoriesSection.row3 .detailCategoriesArea{width: 100%;min-width:0}
    .detailMenus_subInfoTitleBox{padding: 25px 0}

    .productDetailInfo_productText + .detailSubBtn{margin: 30px 0 0}
    .productDetailInfo_countArea + .detailSubBtn{margin: 30px 0 0}
    .productDetailInfo_priceBox + .detailSubBtn{margin: 30px 0 0}

    /*order*/
    .orderPage{padding: 20px 20px 80px;gap: 0;flex-direction: column}
    .orderSection{width: 100%}
    .fixedPoint.order_fixedsection{width: 100%;min-width: 0;margin: 30px 0 0}
    .fixedSection.order_fixedArea{width: 100%;min-width: 0px;padding: 0px;position: static !important}

    .orderProductItem{gap: 10px}
    .orderProductItem_img{width: 50px;min-width: 50px;height: 50px}
 
    .orderTablePrictBox{display: flex;align-items: center;justify-content: space-between;gap: 20px;width:100%;padding-left: 60px}
    .orderProductItem_priceBox{display: flex;flex-direction: column-reverse;justify-content: flex-start;align-items: flex-start;text-align: left}
    .cartProductBox{display: flex;align-items: flex-start;justify-content: space-between;gap: 15px;width:100%}

    .table_tr{display: block;padding: 20px 0 !important;position: relative}
    .table_tr.chkAddTr{padding: 20px 0 20px 30px}
    .table_td{padding: 0}
    .table_td.chkTd{width: 30px !important;min-width: 30px !important;position: absolute;left: 0;top: 20px}
    .table_td{width: 100% !important;min-width: 100% !important}
    .table_td + .table_td{margin: 14px 0 0}
    .table_td.chkTd + .table_td{margin: 0}
    .table_td{justify-content: flex-start;text-align: left}

    .orderTermsBox{margin: 30px 0 0}
    .orderInputBox .inputSubBox{flex-direction: column;gap: 20px}
    .orderInputBox .inputSubBox .inputItemBox{width: 100%}
    .orderInputBox .inputSubBox .inputSubBox{width: 100%}

    .orderArea_title_subItemBox{flex-wrap: wrap;gap:7px 14px}
    .orderArea_title_subItemBox .chk_item_text{font-size: 13px}
    .orderArea_title_subItemBox .chk_item.cartAll label{padding: 0 0 0 21px}
    .orderArea_title_subItemBox .chk_item label::before{width: 16px;height: 16px}

    /*nutrition*/
    .tableTextValItem.mName .tableTextValItem_name{text-align: left}
    .tableTextValItem.mName .tableTextValItem_val{text-align: right}

    .tableTextValItem{width:100%}
    .tableTextValItem_val br{display: none}

    .textBtnItemMFull{border: 1px solid #000;border-radius: 6px;width: 100%;text-decoration: auto;padding: 10px;margin: 5px 0 0}
    .textBtnItemMFull.rColor{border-color: #E9550D}
    .textBtnItemMFull.oColor{border-color: #FF9E18}

    .tableSection + .terms_title{margin: 40px 0 0;padding: 40px 0 0}

    /*mypage*/
    .mypage_contents{display: block;padding-top: 30px}
    .mypage_section{width:100%;min-width: 0}
    .mypageArea_title_subItemBox{flex-wrap: wrap;gap:7px 14px}

    .mypageOrderTableTr{padding: 20px 0 !important}

    .myOrderPaymentInfo{display: flex;flex-direction: column-reverse;border-bottom: 0}
    .myOrderPaymentInfoTextSection{flex-direction: column}
    .myOrderPaymentInfoTextSection + .myOrderPaymentInfoTextSection{border: 0}
    .myOrderPaymentInfoTextArea{width: 100%;min-width: 0;padding: 20px 0}
    .myOrderPaymentInfoTextArea + .myOrderPaymentInfoTextArea{border-top: 1px dashed rgba(0, 0, 0, 0.1)}
    .myOrderPaymentInfoTextBox.paymentMainTextBox .myOrderPaymentInfoText_val{font-size: 17px}
    .myOrderPaymentInfoText_val{font-size: 13px}
    .myOrderPaymentInfoTextArea.paymentArea{padding: 0;border: 0}
    .myOrderPaymentInfoTextBox + .myOrderPaymentInfoTextBox{margin: 13px 0 0}

    .mypageList_notItems{min-height: 50vh}

    .btn_productItem_listLike{width:24px;right: 14px;bottom: 14px}

    .familyList .familyItem{width: calc(50% - 10px);min-width: calc(50% - 10px)}
    .familyList .familyItemAddBtn{width: calc(50% - 10px);min-width: calc(50% - 10px)}

    .table_tr.ingredientTr{display: flex;flex-wrap: wrap;gap: 0 12px}
    .table_tr.ingredientTr .table_td{width: calc(50% - 6px) !important;min-width: calc(50% - 6px) !important}
    .table_tr.ingredientTr .table_td:last-child,.table_tr.ingredientTr .table_td.mFullTd{width:100% !important;min-width: 100% !important}
    .ingredientType_btnBox{height: 42px;width: 100%}
    .ingredientType{width: 100%;position: relative;padding: 0 40px}
    .btn_ingredientType{width: 12px;position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
    .btn_ingredientType img{width: 100%}
    .ingredientKeySection{padding: 0;border: 0;border-radius: 0}

    .tapBox.pcType{border: 0;gap: 14px;margin: 0 0 6px}
    .tapBox.pcType .tapItem{font-size: 16px}

}
@media (max-width:960px){
    .contentsSection.pcFooterContents{min-height: 100vh}
    .contentsSection.footerContents{min-height: calc(100vh - 447px)}
    
    .footer.pcItem{display: none}

    .footer{height: 447px}
    .footer_infoArea{display: block;padding: 20px 0}
    .footer_infoBox{gap: 12px;flex-direction: column;width:100%}
    .footer_iconBox{margin: 20px 0 0;gap: 15px;justify-content: flex-start}
    .footer_iconBox>img{width: 100%;max-width: 70px}

    .input_contents{padding: 50px 20px 80px}
    .input_contents_titleBox{margin: 0 0 40px}
    .welcome_sns{margin: 40px auto 22px}

    .pageBtn_box.mRow2,.btn_subBox.mRow2{flex-direction: column !important}
    .pageBtn_box.mRow2 .pageBtn{width: 100%}

    /*main*/
    .mainBannerImg{height: 240px}
    .mainBannerControll .swiper-button-prev,.mainBannerControll .swiper-button-next{width:36px;height: 36px}
    .mainDataHealthInfo_notDataBtnBox{gap: 20px;flex-direction: column}
    .mainDataHealthInfo_notDataBtnBox .dataBtn{width:100%;max-width: 300px}

    /*survey*/
    .surveyDetail_lineChartBox{height: 235px;min-width: 700px}

    /*detail*/
    .productDetailInfo_section{margin: 0 auto;padding: 0 0 2.5em;flex-direction: column;gap: 30px}
    .productDetailInfo_img{border-radius: 0;width: 100%;height: 0;padding: 0 0 100%;min-width: 0}
    .productDetailInfo_area{width: 100%;padding: 0 20px 40px}
    .productDetailInfo_productText{margin: 10px 0 0}
    .productDetailInfo_subInfoTitleBox{padding: 25px 0}

    .detailTapBox{padding: 0}
    .fixedTapPoint{min-height: 36px}

    .recipeDetailSection{gap: 40px 20px}
    .recipeDetailItem{width: 100%;flex-direction: column;gap: 20px}
    .recipeDetailItem_img{width: 100%;height: auto;padding: 0 0 100%;min-width: 0}
    .recipeDetailItem_textArea{width:100%}
    .recipeDetailItem_step{font-size: 16px}
    .recipeDetailItem_imgSelBox{margin: 20px 0 0}

    /*subInfoTextBox*/
    .subInfoTextArea.subInfoTextArea_img>img{display: none}
    .subInfoTextSection.row2{flex-direction: column;gap: 15px}
    .subInfoTextSection.row2 .subInfoTextArea{width: 100%;min-width: 0}

    /*nutrition*/
    .surveyDetail_productDetailArea .productDetailInfo_area{padding: 0}
    .surveySelectSelItem{align-items: flex-start}
    .surveySelectSelItem_controllBox{flex-direction: column-reverse;align-items: flex-end}

    .surveyDetail_btnBox{margin: 40px auto 0}

    /*pageNation*/
    .pageNation{margin-top: 20px}

    /*mypage*/
    .mypageBtnBox{margin: 40px auto 0}

    .marketMainBanner .swiper{width: 275vw}
}
@media (max-width: 720px){
  .familyList .familyItem{width: 100%;min-width: 0}
  .familyList .familyItemAddBtn{width: 100%;min-width: 0}
}
@media (max-width: 650px){
    .marketMainBanner .swiper{width: 270vw}
}
@media (max-width: 550px){
  .surveyDetailConsultingItemBox{max-width: calc(100% - 20px)}
}
@media (max-width: 490px){
  .productDetailInfo_productText + .detailSubBtn{margin: 30px auto 0}
  .productDetailInfo_countArea + .detailSubBtn{margin: 30px auto 0}
  .productDetailInfo_priceBox + .detailSubBtn{margin: 30px auto 0}

  .marketMainBanner .swiper{width: 260vw}
}
@media (max-width: 400px){
  .sideMenu{width: calc(100% - 40px)}
}
@media (max-width: 360px){
  .cardSlideControll{display: none}

  .marketMainBanner .swiper{width: 250vw}
}
/*//*/